import React, { useState, useEffect } from "react";
import { db } from "../db";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrency } from "../components/Redux/Slices/currencySlice";
import DeleteModal from "../components/DeleteModal";
import "../assets/styles/style.css";
import ExpenseLists from "../components/ExpenseLists";
import { IoChevronBackCircleOutline } from "react-icons/io5";

export default function DetailsScreen() {
  const [expenses, setExpenses] = useState([]);
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState(null);
  const [setCategoryExpenses] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { category } = useParams();
  const [expenseToDelete, setExpenseToDelete] = useState(null);
  const selectedCurrency = useSelector(selectCurrency);
  const categoryId = parseInt(category);
  const handleDelete = async (id) => {
    setShowDeleteModal(true);
    setExpenseToDelete(id);
  };
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const confirmDelete = async () => {
    try {
      await db.expenses.update(expenseToDelete, { isDeleted: 1 });
      const updatedExpenses = expenses.filter(
        (expense) => expense.id !== expenseToDelete
      );
      setExpenses(updatedExpenses);

      setCategoryExpenses(updateCategoryExpenses(updatedExpenses));
    } catch (error) {
      console.error("Error deleting expense:", error);
    }
    setMessage("Successfully Deleted");
    setMessageType("success");
    setShowDeleteModal(false);
  };
  const handleEdit = async (id) => {
    try {
      localStorage.setItem("activeRow-detailsScreen", id);
      navigate(`/edit/${id}`);
    } catch (error) {
      console.error("Error editing expense:", error);
    }
  };

  const updateCategoryExpenses = (expenses) => {
    const updatedCategoryExpenses = {};

    expenses.forEach((expense) => {
      if (!updatedCategoryExpenses[expense.category]) {
        updatedCategoryExpenses[expense.category] = [];
      }
      updatedCategoryExpenses[expense.category].push(expense);
    });

    return updatedCategoryExpenses;
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setExpenseToDelete(null);
  };

  const setActive = (id) => {
    const newActiveRow = activeRow === id ? null : id;
    localStorage.setItem("activeRow-detailsScreen", newActiveRow);
    setActiveRow(newActiveRow);
  };

  useEffect(() => {
    const storedActiveRow = localStorage.getItem("activeRow-detailsScreen");
    if (storedActiveRow) {
      setActiveRow(parseInt(storedActiveRow));
    }
  }, []);

  window.onbeforeunload = function () {
    localStorage.removeItem("activeRow-detailsScreen");
  };

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const expensesFromDB = await db.expenses.toArray();
        setExpenses(expensesFromDB);
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    };

    fetchExpenses();
  }, []);
const filteredExpenses = expenses.filter((expense) => {
  if (isNaN(categoryId)) {
    return (
      (expense.category === null || isNaN(expense.category)) &&
      expense.isDeleted !== 1
    );
  } else {
    return expense.category === categoryId && expense.isDeleted !== 1;
  }
});


  return (
    <>
      <div className="   main-div container p-2  position-relative">
        <button
          className="  back-btn  "
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoChevronBackCircleOutline />
        </button>
        <h4 className="  heading-txt ">Expense Items</h4>
        <div className="card child-div">
          <div className="pt-2">
            <ExpenseLists
              filteredExpenses={filteredExpenses}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              activeRow={activeRow}
              selectedCurrency={selectedCurrency}
              setActive={setActive}
            />
          </div>
          <div className="col  ">
            <DeleteModal
              isOpen={showDeleteModal}
              onCancel={cancelDelete}
              onConfirm={confirmDelete}
            />
          </div>
          <span className=" noSpace SuccessMessage-div ">
            {message && (
              <div
                className={` successMessage ${
                  messageType === "success" ? "alert-success" : "alert-danger"
                }`}
              >
                <span className="material-symbols-outlined successMessage">
                  task_alt
                </span>
                <span className="successMessage;"> {message} </span>
              </div>
            )}
          </span>
        </div>
      </div>
    </>
  );
}
