import React from "react";
import {  useNavigate } from "react-router-dom";
import "../assets/styles/style.css";

function NoResultFound() {
    const navigate = useNavigate()
  return (
    <div className=" container-fluid  FourZeroFour-container ">
      {" "}
      <div className="row  login-container">
        <div className="col-lg-6 col-md-6 col-sm-12   border-0 display-center       ">
          <div className="FourZeroFour-img ">
            <img
              src="assets/data-not-found.svg"
              alt="data-not-found"
              className="noResultfound-img"
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12  display-center ">
          <div className="shadow   text-center text-color FourZeroFour-card ">
            <div className="noSpace">
              <span className="fs-1 text-orange "> Oops !</span>{" "}
            </div>
            <br />
            <br />
            <p>No results found.</p>
            <br />
            <br />
            <button
              className="save-btn rounded-pill"
              onClick={() => {
                navigate("/SearchScreen");
              }}
            >
              Try again
            </button>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default NoResultFound;
