import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    status: "",
    expiryDate: "",
  },
  reducers: {
    setSubscriptionStatus: (state, action) => {
      state.status = action.payload;
    },
    setExpiryDate: (state, action) => {
      state.expiryDate = action.payload;
    },
  },
});

export const { setSubscriptionStatus, setExpiryDate } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
