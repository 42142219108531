import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import db from "../db";
import CategoryLists from "../components/CategoryLists";
import "../assets/styles/style.css";
import { IoChevronBackCircleOutline } from "react-icons/io5";

function AddCategory() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);
  // const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    const categoriesFromDB = await db.categories.toArray();
    setCategories(categoriesFromDB);
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    const newCategory = {
      name: name.trim(),
      parentId: selectedParent ? selectedParent.id : null,
      userId: "someUserId",
      isEdited: 1,
    };
    await db.categories.add(newCategory);
    setName("");
    setSelectedParent(null);
    loadCategories();
    setMessage("Successfully Added");
    setMessageType("success");
  };

  return (
    <div className="container main-div p-2 pt-4 ">
      <button
        className="  back-btn  "
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBackCircleOutline />
      </button>
      <h4 className="heading-txt mb-3">Add Category</h4>

      <form onSubmit={handleAddCategory} className="card child-div  ">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label className=" text-color pb-2">Category</label>
            <input
              type="text"
              className="form-control mb-4 "
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label className=" pb-2 text-color">Parent Category</label>
            <CategoryLists
              categories={categories?.filter(
                (category) => category.isDeleted !== 1
              )}
              selectedParent={selectedParent}
              onChange={(e) =>
                setSelectedParent(
                  e.target.value
                    ? categories.find((c) => c.id === parseInt(e.target.value))
                    : null
                )
              }
            />
          </div>
        </div>
        <button className=" save-btn  button rounded-pill " type="submit">
          Save
        </button>
        <span className=" noSpace SuccessMessage-div ">
          {message && (
            <div
              className={`  successMessage ${
                messageType === "success" ? "alert-success" : "alert-danger"
              }`}
            >
              <span className="material-symbols-outlined successMessage">
                task_alt
              </span>
              <span className="successMessage;"> {message} </span>
            </div>
          )}
        </span>
      </form>
    </div>
  );
}
export default AddCategory;
