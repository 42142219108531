import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function DeleteModal({ isOpen, onCancel, onConfirm }) {
  return (
    <>
      <div>
        <Modal show={isOpen} onHide={onCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onConfirm}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
