import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/NavbarStyle.css";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    const pathname = location.pathname;
    switch (pathname) {
      case "/Home":
        setActiveItem("Home");
        break;
      case "/SearchScreen":
        setActiveItem("Reports");
        break;
      case "/ManageCategories":
        setActiveItem("Categories");
        break;
      case "/Settings":
        setActiveItem("Settings");
        break;
      case "/AddExpenses":
        setActiveItem("AddExpenses");
        break;

      default:
        setActiveItem("");
        break;
    }
  }, [location]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg position-fixed w-100  p-0 m-0 top-navbar">
        <div className="container-fluid   position-relative">
          <div className="Navbar-Position  ">
            <div className="d-flex ">
              <div className="row gx-3 gy-3 ">
                <div className="col-3">
                  <span
                    className={`nav-item  ${
                      activeItem === "Home" ? "active" : ""
                    }`}
                    onClick={() => navigate("/Home")}
                  >
                    <div className="navbar-contents   ">
                      <span className="nav-icon">
                        <i className="material-symbols-outlined">home</i>{" "}
                        {/* <img src="./assets/home-icon.svg" alt="homeIcon" /> */}
                      </span>
                      <span className="nav-text"> Home</span>
                    </div>
                  </span>
                </div>
                <div className="col-3  ">
                  <span
                    className={`nav-item  ${
                      activeItem === "Reports" ? "active" : ""
                    }`}
                    onClick={() => navigate("/SearchScreen")}
                  >
                    <div className=" navbar-contents  ">
                      <span className="nav-icon">
                        <i className="material-symbols-outlined">lab_profile</i>
                      </span>
                      <span className="nav-text"> Reports</span>
                    </div>
                  </span>
                </div>
                <div className="col-3 ">
                  <span
                    className={`nav-item  ${
                      activeItem === "Categories" ? "active" : ""
                    }`}
                    onClick={() => navigate("/ManageCategories")}
                  >
                    <div className="navbar-contents   ">
                      <span className="nav-icon">
                        <i className="material-symbols-outlined">category</i>
                      </span>
                      <span className="nav-text"> Categories</span>
                    </div>
                  </span>
                </div>
                <div className="col-3">
                  <span
                    className={`nav-item  ${
                      activeItem === "Settings" ? "active" : ""
                    }`}
                    onClick={() => navigate("/Settings")}
                  >
                    <div className="navbar-contents ">
                      <span className="nav-icon ">
                        <i className="material-symbols-outlined">settings</i>
                      </span>

                      <span className="nav-text"> Settings</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="addExpense-btn p-0">
            <span
              className={`nav-item addExpenseIcon ${
                activeItem === "AddExpenses" ? "active" : ""
              }`}
              onClick={() => navigate("/AddExpenses")}
            >
              <i className="material-symbols-outlined circleIcon text-white">
                add_circle
              </i>
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
