import React, { useState, useEffect } from "react";

const SubscriptionPrompt = ({ expiryDate }) => {
  const [daysLeft, setDaysLeft] = useState(null);

  useEffect(() => {
    if (expiryDate) {
      // Calculate the number of days left until subscription expires
      const currentDate = new Date();
      const expirationDate = new Date(expiryDate);
      const timeDiff = expirationDate - currentDate;
      const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      setDaysLeft(daysRemaining);
    }
  }, [expiryDate]);

  const renderDaysLeft = () => {
    if (daysLeft === 0) return "today";
    if (daysLeft === 1) return "tomorrow";
    return `${daysLeft} days`;
  };

  return (
    <>
      {daysLeft !== null && daysLeft <= 4 && daysLeft >= 0 && (
        <div className="subscription-prompt">
          <span className="me-2 ">
            Your subscription will expire {renderDaysLeft()}.
          </span>
          <button className="btn btn-warning">Renew</button>
        </div>
      )}
    </>
  );
};

export default SubscriptionPrompt;
