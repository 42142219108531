import axios from "axios";
import db from "./db";
import { setSubscriptionStatus, setExpiryDate } from "./components/Redux/Slices/subscriptionSlice";


const LoginAPI = process.env.REACT_APP_API_URL_LOGIN;
const SignupAPI = process.env.REACT_APP_API_URL_SIGNUP;
const LogoutAPIURL = process.env.REACT_APP_API_URL_LOGOUT;
const ForgotAPIURL = process.env.REACT_APP_API_URL_FORGOT;
const LogoutOTPURL = process.env.REACT_APP_API_URL_LOGOUT_OTP;
const ForgotOTPURL = process.env.REACT_APP_API_URL_FORGOT_OTP;
const SyncRecordsAPIURL = process.env.REACT_APP_API_URL_SYNC_RECORDS;
const fetchChangeRecordsAPIURL =
  process.env.REACT_APP_API_URL_FETCH_CHANGE_RECORDS;
const fetchinitialrecordsAPIURL =
  process.env.REACT_APP_API_URL_FETCH_INITIAL_RECORDS;
const submitinitialrecordsAPIURL =
  process.env.REACT_APP_API_URL_SUBMIT_INITIAL_RECORDS;
const ChangePasswordAPIURL = process.env.REACT_APP_API_URL_CHANGE_PASSWORD;
const ChangeUserNameURL = process.env.REACT_APP_API_URL_CHANGE_USERNAME;
const SubscriptionStatusURL = process.env.REACT_APP_API_URL_SUBSCRIPTION_STATUS;

const accessToken = localStorage.getItem("accessToken");
const user_id = localStorage.getItem("user_id");

export const GoogleLogin = process.env.REACT_APP_API_URL_GoogleLogin;

export const SyncRecords = async () => {
  try {
    const editedExpenses = await db.expenses.where({ isEdited: 1 }).toArray();
    const deletedExpenses = await db.expenses.where({ isDeleted: 1 }).toArray();
    const editedCategories = await db.categories
      .where({ isEdited: 1 })
      .toArray();
    const deletedCategories = await db.categories
      .where({ isDeleted: 1 })
      .toArray();
    console.log("Calling to Sync Records");
    const payload = {
      user_id: user_id,
      expenses: {
        deleted: deletedExpenses.map((expense) => expense.id),
        changed: editedExpenses.reduce((acc, expense) => {
          acc[expense.id] = {
            item: expense.item,
            amount: expense.amount,
            quantity: expense.quantity,
            date: expense.date,
            currency: expense.currency,
            user_category_id: parseInt(expense.category),
          };
          return acc;
        }, {}),
      },
      categories: {
        deleted: deletedCategories.map((category) => category.id),
        changed: editedCategories.reduce((acc, category) => {
          acc[category.id] = {
            name: category.name,
            parent_id: parseInt(category.parentId),
          };
          return acc;
        }, {}),
      },
    };

    const response = await axios.post(SyncRecordsAPIURL, payload, {
      headers: {
        Authorization: accessToken,
      },
    });

    if (response.status === 200) {
      if (
        response.data.status === "success" ||
        response.data.status === "partial success"
      ) {
        if (deletedExpenses.length > 0) {
          await db.expenses.where({ isDeleted: 1 }).delete();
          console.log("Deleted expense records from IndexedDB successfully");
        }
        if (editedExpenses.length > 0) {
          await db.expenses.where({ isEdited: 1 }).modify({ isEdited: 0 });
        }

        if (deletedCategories.length > 0) {
          await db.categories.where({ isDeleted: 1 }).delete();
          console.log("Deleted category records from IndexedDB successfully");
        }
        if (editedCategories.length > 0) {
          await db.categories.where({ isEdited: 1 }).modify({ isEdited: 0 });
        }
        console.log(response.data.message);
        console.log("Data imported successfully:", response.data.message);
      } else {
        console.log(response.data.message);
      }
    } else {
      console.error("Error syncing data:", response.statusText);
    }
  } catch (error) {
    console.error("Error syncing data:", error);
  }
};

export const fetchChangedRecords = async () => {
  try {
    const deviceId = localStorage.getItem("device_id");
    const response = await axios.get(
      `${fetchChangeRecordsAPIURL}?deviceId=${deviceId}`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    const { expenses, categories } = response.data;

    const expensesToAdd = Array.isArray(expenses?.changed)
      ? expenses.changed.map((expense) => ({
          item: expense.item,
          quantity: expense.quantity,
          category: parseFloat(expense.category_id),
          amount: parseFloat(expense.amount),
          currency: expense.currency,
          date: expense.date,
          isEdited: 0,
          isDeleted: 0,
          id: parseFloat(expense.id),
          userId: user_id,
        }))
      : [];

    const categoriesToAdd = Array.isArray(categories?.changed)
      ? categories.changed.map((category) => ({
          name: category.name,
          parentId: parseFloat(category.parent_id),
          userId: user_id,
          isDeleted: 0,
          isEdited: 0,
          id: category.id,
        }))
      : [];

    const expensesToDelete = Array.isArray(expenses?.deleted)
      ? expenses.deleted
      : [];
    const categoriesToDelete = Array.isArray(categories?.deleted)
      ? categories.deleted
      : [];

    await db.transaction("rw", db.expenses, db.categories, async () => {
      for (const expense of expensesToAdd) {
        const existingExpense = await db.expenses
          .where("id")
          .equals(expense.id)
          .first();
        if (existingExpense) {
          await db.expenses.update(expense.id, expense);
        } else {
          await db.expenses.add(expense);
        }
      }

      for (const category of categoriesToAdd) {
        const existingCategory = await db.categories
          .where("id")
          .equals(category.id)
          .first();
        if (existingCategory) {
          await db.categories.update(category.id, category);
        } else {
          await db.categories.add(category);
        }
      }

      for (const expenseId of expensesToDelete) {
        await db.expenses.where("id").equals(expenseId).delete();
      }

      for (const categoryId of categoriesToDelete) {
        await db.categories.where("id").equals(categoryId).delete();
      }
    });
  } catch (error) {
    console.error("Error syncing data from MySQL:", error);
  }
};

export const submitInitialRecords = async (user_id, accessToken) => {
  try {
    const expensesFromDB = await db.expenses.toArray();
    const categoriesFromDB = await db.categories.toArray();

    const payload = {
      user_id: user_id,
      expenses: {
        added: expensesFromDB.reduce((acc, expense) => {
          acc[expense.id] = {
            item: expense.item,
            amount: expense.amount,
            quantity: expense.quantity,
            date: expense.date,
            currency: expense.currency,
            user_category_id: parseInt(expense.category),
          };
          return acc;
        }, {}),
      },
      categories: {
        added: categoriesFromDB.reduce((acc, category) => {
          acc[category.id] = {
            name: category.name,
            parent_id: parseInt(category.parentId),
          };
          return acc;
        }, {}),
      },
    };

    const response = await axios.post(submitinitialrecordsAPIURL, payload, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response.data;
    //console.log("Data imported successfully:");
  } catch (error) {
    console.error("Error importing data:", error);
  }
};

export const fetchInitialRecord = async (accessToken) => {
  try {
    const response = await axios.get(fetchinitialrecordsAPIURL, {
      headers: {
        Authorization: accessToken,
      },
    });

    const { expenses, categories } = response.data.data;
    const expensesToAdd = expenses.map((expense) => ({
      id: expense.id,
      item: expense.item,
      quantity: expense.quantity,
      amount: parseFloat(expense.amount),
      currency: expense.currency.trim(),
      date: expense.date,
      userId: user_id,
      category: expense.category_id,
      isDeleted: 0,
      isEdited: 0,
    }));

    const categoriesToAdd = categories.map((category) => ({
      id: category.id,
      name: category.name,
      parentId: category.parent_id,
      userId: user_id,
      isDeleted: 0,
      isEdited: 0,
    }));

    await db.transaction("rw", db.expenses, db.categories, async () => {
      await db.expenses.bulkAdd(expensesToAdd);
      await db.categories.bulkAdd(categoriesToAdd);
    });

    //console.log("Data imported successfully!");
  } catch (error) {
    //console.error("Error importing data:", error);
  }
};

export const ChangePasswordAPI = async (
  currentPassword,
  newPassword,
  confirmPassword
) => {
  try {
    const payload = {
      currentPassword,
      newPassword,
      confirmPassword,
    };

    const response = await axios.post(ChangePasswordAPIURL, payload, {
      headers: {
        Authorization: accessToken,
      },
    });

    return response.data;
  } catch (error) {
    //console.error("Error changing password:", error);
    throw error;
  }
};
export const Logout = async () => {
  try {
    const response = await axios.post(LogoutAPIURL, null, {
      headers: {
        Authorization: accessToken,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
    }
    throw error;
  }
};
export const Forgot = async (email) => {
  try {
    const payload = {
      email,
    };
    const response = await axios.post(ForgotAPIURL, payload);
    return response.data;
  } catch (error) {
    // setSendForgetOTP(error);
    console.error("Error sending email:", error);
    if (error.response) {
      console.error("Error data:", error.response.data);
      return error.response.data;
    }
    return { status: "error", message: "An error occurred" };
  }
};
export const ForgotOTP = async (
  forgotToken,
  ForgetOTP,
  newPassword,
  confirmPassword
) => {
  try {
    const payload = {
      code: ForgetOTP,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    const response = await axios.post(ForgotOTPURL, payload, {
      headers: {
        Authorization: forgotToken,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return { status: "error", message: "An error occurred" };
  }
};
export const Login = async (email, password, deviceId) => {
  const payload = {
    email: email,
    password: password,
    device_id: deviceId,
  };

  try {
    const response = await axios.post(LoginAPI, payload);
    return response.data;
  } catch (error) {
    throw new Error("Error logging in. Please try again.");
  }
};
export const Register = async (username, email, password) => {
  const payload = {
    username: username,
    email: email,
    password_hash: password,
  };

  try {
    const response = await axios.post(SignupAPI, payload);
    return response.data;
  } catch (error) {
    throw new Error("Error logging in. Please try again.");
  }
};
export const LogoutOTP = async (OTP) => {
  const payload = {
    code: OTP,
  };
  try {
    const response = await axios.post(LogoutOTPURL, payload, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response.data;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

export const ChangeUserName = async (userName) => {
  const payload = {
    username: userName,
  };
  try {
    const response = await axios.post(ChangeUserNameURL, payload, {
      headers: {
        Authorization: accessToken,
      },
    });
    if (response.data.status === "success") {
      localStorage.setItem("username", userName);
    }
    console.log("response.data.status", response.data.status);
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};


export const fetchSubscriptionStatus = (dispatch) => async () => {
  try {
    const response = await axios.get(SubscriptionStatusURL, {
      headers: {
        Authorization: accessToken,
      },
    });

    const data = response.data;

    // Dispatch the updated subscription status to Redux
    dispatch(setSubscriptionStatus(data.subscription_status));
    dispatch(setExpiryDate(data.end_date));

 
    // Save data to localStorage
    const subscription_next_billing_date = new Date(data.next_billing_date);
    const next_billing_date = subscription_next_billing_date.toLocaleDateString(
      "en-US",
      {
        day: "numeric",
        month: "long",
        year: "numeric",
      }
    );

    const end_date = new Date(data.end_date);
    const subscription_end_date = end_date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    localStorage.setItem("next_billing_date", next_billing_date);
    localStorage.setItem("subscription_end_date", subscription_end_date);
    localStorage.setItem("subscription_plan", data.subscription_plan);
    localStorage.setItem("subscription_status", data.subscription_status);
  } catch (error) {
    console.error("Failed to fetch subscription status:", error);
  }
};
