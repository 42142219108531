import { configureStore } from "@reduxjs/toolkit";
import currencyReducer from "./Slices/currencySlice";
import subscriptionReducer from "./Slices/subscriptionSlice";

const store = configureStore({
  reducer: {
    currency: currencyReducer,
    subscription: subscriptionReducer,
  },
});

export default store;
