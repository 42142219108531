import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SubscriptionForm = () => {
  const [plan, setPlan] = useState('trial');  
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Use useNavigate hook for redirection
  const token = localStorage.getItem("accessToken");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage('');
    await PremiumPlanAPI(); // Ensure this function is awaited
  };

  const payload = {
    plan: plan
  };

  const PremiumPlanAPI = async () => {
    try {
      const response = await axios.post("http://expense-tracking.local:8083/apiauth/web/subscription/create", payload, {
        headers: {
          Authorization: token
        }
      });

      console.log(response.data.status);
      if (response.status === 200 && response.data.status == 'success') {
        // Redirect to home page if the subscription is successful
        navigate('/home');
      } else {
        // Show error message if the response indicates failure
        setMessage('Subscription failed. Please try again.');
      }
    } catch (error) {
      // Handle any errors
      setMessage('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Subscribe to a Plan</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Select Plan:
          <select value={plan} onChange={(e) => setPlan(e.target.value)} required>
            <option value="trial">Trial Plan</option>
            <option value="premium">Premium Plan</option>
          </select>
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Subscribe'}
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default SubscriptionForm;
