import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import db from "../db";
import CategoryLists from "../components/CategoryLists";
import { IoChevronBackCircleOutline } from "react-icons/io5";

const EditCategory = () => {
  const { categoryId } = useParams();
  const [newName, setNewName] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [newParentId, setNewParentId] = useState("");
  const navigate = useNavigate();
  const [userAddedCategories, setUserAddedCategories] = useState([]);

  const loadCategory = useCallback(async () => {
    const categoryFromDB = await db.categories.get(Number(categoryId));

    setNewName(categoryFromDB.name);
    setNewParentId(categoryFromDB.parentId || "");
  }, [categoryId, setNewName, setNewParentId]);

  const loadParentCategories = useCallback(async () => {
    const parentCategoriesFromDB = await db.categories
      .filter((cat) => !cat.parentId)
      .toArray();
    setParentCategories(parentCategoriesFromDB);
  }, [setParentCategories]);

  useEffect(() => {
    loadCategory();
    loadParentCategories();
  }, [loadCategory, loadParentCategories]);

  const handleEditCategory = async () => {
    await db.categories.update(Number(categoryId), {
      name: newName,
      parentId: newParentId !== "" ? Number(newParentId) : null,
      isEdited: 1,
    });
    setMessage("Successfully updated");
    setMessageType("success");
  };

  useEffect(() => {
    const fetchUserAddedCategories = async () => {
      try {
        const categoriesFromDB = await db.categories.toArray();
        setUserAddedCategories(categoriesFromDB);
      } catch (error) {
        console.error("Error fetching user-added categories:", error);
      }
    };
    fetchUserAddedCategories();
  }, []);

  return (
    <div className="container main-div p-2  ">
      <button
        className="  back-btn  "
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBackCircleOutline />
      </button>
      <h4 className="heading-txt mb-3">Update Category</h4>

      <div className=" card child-div  ">
        <div className="row ">
          <div className=" col-lg-6 col-md-6 col-sm-12 mb-2">
            <label className=" text-color " htmlFor="name">
              Category
            </label>
            <input
              type="text"
              id="name"
              className="mb-4 form-control"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
            <label className=" text-color" htmlFor="parentId">
              Parent Category
            </label>
            <CategoryLists
              categories={parentCategories}
              selectedParent={userAddedCategories.find(
                (c) => c.id === Number(newParentId)
              )}
              onChange={(e) => setNewParentId(e.target.value)}
            />
          </div>
        </div>

        <button
          className="save-btn  button rounded-pill"
          onClick={handleEditCategory}
        >
          Save
        </button>
        <span className=" noSpace SuccessMessage-div ">
          {message && (
            <div
              className={` successMessage ${
                messageType === "success" ? "alert-success" : "alert-danger"
              }`}
            >
              <span className="material-symbols-outlined successMessage">
                task_alt
              </span>
              <span className="successMessage;"> {message} </span>
            </div>
          )}
        </span>
      </div>
    </div>
  );
};

export default EditCategory;
