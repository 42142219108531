import { createSlice } from "@reduxjs/toolkit";

const storedCurrency = localStorage.getItem("selectedCurrency");
const initialCurrency = storedCurrency
  ? JSON.parse(storedCurrency)
  : { code: "INR", label: "₹ ", flag: "IN" };
localStorage.setItem("selectedCurrency", JSON.stringify(initialCurrency));
const currencySlice = createSlice({
  name: "currency",
  initialState: {
    selectedCurrency: initialCurrency,
  },
  reducers: {
    setCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
  },
});

export const { setCurrency } = currencySlice.actions;
export const selectCurrency = (state) => state.currency.selectedCurrency;
export default currencySlice.reducer;
