import React from "react";
import { Modal } from "react-bootstrap";
import "../assets/styles/style.css";

function SessionExpireModal({ show, handleResentOTP, loading }) {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="fw-bold">Session expired !</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="display-center text-danger fw-bold">
          <span className="text-center">  <img src="/assets/session_timeout.png" alt="session" height={350} />
          <br />
         OTP expired please try again !</span>
        </div>
      </Modal.Body>
      <br />
      <div className="text-center pb-4">
        <button
          className="btn btn-warning w-50   "
          disabled={loading}
          onClick={handleResentOTP}
        >
          {loading && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}

          {loading ? "Resending..." : "Resend OTP"}
        </button>
      </div>{" "}
      {/* <div className="display-center">
        <button
          className="btn btn-warning w-50"
          style={{ borderRadius: "10px" }}
          onClick={handleResentOTP}
        >
          Resend OTP
        </button>
      </div> */}
    </Modal>
  );
}

export default SessionExpireModal;
