import React from "react";

const CategoryLists = ({
  categories,
  selectedParent,
  onChange,
  disableChild,
}) => {
  const categoryOptions = (category, level = 0) => {
    // const indentation = Array(level).fill("-").join(" ");
    const indentation = Array(level).fill("\u00A0\u00A0\u00A0\u00A0").join("");

    const optionStyle = {
      fontWeight: category.parentId ? "normal" : "bold",
    };

    const isChildDisabled =
      disableChild && level > 0 && category.parentId !== selectedParent?.id;

    return (
      <React.Fragment key={category.id}>
        <option
          value={category.id}
          style={optionStyle}
          disabled={isChildDisabled}
        >
          {indentation} {category.name}
        </option>
        {category.children &&
          category.children.map((child) =>
            categoryOptions(child, level + 1)
          )}
      </React.Fragment>
    );
  };
  const categoryForm = (flatCategories) => {
    const sortedCategories = flatCategories.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const categoryMap = {};
    const categoryTree = [];

    sortedCategories.forEach((category) => {
      category.children =
        categoryMap[category.id] && categoryMap[category.id].children;
      categoryMap[category.id] = category;

      if (category.parentId) {
        categoryMap[category.parentId] = categoryMap[category.parentId] || {};
        categoryMap[category.parentId].children =
          categoryMap[category.parentId].children || [];
        categoryMap[category.parentId].children.push(category);
      } else {
        categoryTree.push(category);
      }
    });
    return categoryTree;
  };

  return (
    <div className="select-wrapper">
      <select
        className="form-control category-select"
        value={selectedParent ? selectedParent.id : ""}
        onChange={onChange}
      >
        <option value=""></option>
        {categoryForm(categories).map((category) => categoryOptions(category))}
      </select>
      <i className="down-arrow material-symbols-outlined">arrow_drop_down</i>
    </div>
  );
};

export default CategoryLists;
