import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../db";
import { useSelector } from "react-redux";
import { selectCurrency } from "../components/Redux/Slices/currencySlice";
import formInputsJson from "../components/formInputs.json";
import CategoryLists from "../components/CategoryLists";
import { Button } from "react-bootstrap";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";

const EditExpense = () => {
  const { expenseId } = useParams();
  const navigate = useNavigate();
  const formInputs = formInputsJson.formInputs;
  const selectedCurrency = useSelector(selectCurrency);
  const [userAddedCategories, setUserAddedCategories] = useState([]);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [formData, setFormData] = useState({
    item: "",
    quantity: "",
    category: "",
    amount: "",
    currency: "",
    date: "",
  });

  useEffect(() => {
    const fetchExpense = async () => {
      try {
        const expense = await db.expenses.get(parseInt(expenseId, 10));
        if (expense) {
          setFormData({
            item: expense.item,
            quantity: expense.quantity,
            category: parseInt(expense.category),
            amount: expense.amount,
            currency: expense.currency,
            date: expense.date,
          });
        } else {
          console.error("Expense not found");
        }
      } catch (error) {
        console.error("Error fetching expense:", error);
      }
    };
    fetchExpense();
  }, [expenseId]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await db.expenses.update(parseInt(expenseId, 10), {
        ...formData,
        isEdited: 1,
      });
      setMessage("Successfully Updated");
      setMessageType("success");
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error updating expense:", error);
      setMessage("Error updating expense");
      setMessageType("danger");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      category: value,
    }));
  };
  useEffect(() => {
    const fetchUserAddedCategories = async () => {
      try {
        const categoriesFromDB = await db.categories.toArray();
        setUserAddedCategories(categoriesFromDB);
      } catch (error) {
        console.error("Error fetching user-added categories:", error);
      }
    };

    fetchUserAddedCategories();
  }, []);

  return (
    <div className="container p-2 pt-4 main-div ">
      <button
        className="  back-btn  "
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBackCircleOutline />
      </button>
      <h4 className="heading-txt ">Update Expense</h4>

      <div className="card child-div    ">
        <form onSubmit={handleSave}>
          <div className="row pb-5 ">
            {formInputs.map((input, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-2">
                {input.type === "select" ? (
                  <div>
                    <label htmlFor={input.id} className="form-label">
                      {input.label}
                    </label>
                    <CategoryLists
                      categories={userAddedCategories?.filter(
                        (category) => category.isDeleted !== 1
                      )}
                      selectedParent={{ id: formData.category }}
                      onChange={handleCategoryChange}
                    />
                  </div>
                ) : (
                  <div>
                    <label htmlFor={input.id} className="form-label">
                      {input.label}
                    </label>
                    {input.name === "amount" ? (
                      <NumericFormat
                        value={formData.amount}
                        onValueChange={(values) => {
                          const { value } = values;
                          setFormData((prevData) => ({
                            ...prevData,
                            amount: value,
                          }));
                        }}
                        thousandsGroupStyle="lakh"
                        thousandSeparator=","
                        prefix={selectedCurrency.label}
                        placeholder={input.placeholder}
                        className="form-control currencyIcons"
                        required={input.required}
                      />
                    ) : (
                      <input
                        type={input.type}
                        id={input.id}
                        name={input.name}
                        value={formData[input.name]}
                        onChange={
                          input.name === "category"
                            ? handleCategoryChange
                            : handleChange
                        }
                        className="form-control"
                        placeholder={input.placeholder}
                        required={input.required}
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <Button
            className="save-btn button rounded-pill"
            variant="success"
            type="submit"
          >
            Save
          </Button>
          <span className="noSpace SuccessMessage-div">
            {message && (
              <div
                className={` successMessage ${
                  messageType === "success" ? "alert-success" : "alert-danger"
                }`}
              >
                <span className="material-symbols-outlined successMessage">
                  task_alt
                </span>
                <span className="successMessage;"> {message} </span>
              </div>
            )}
          </span>
        </form>
      </div>
    </div>
  );
};

export default EditExpense;
