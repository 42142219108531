import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import db from "../db";
import CategoryLists from "../components/CategoryLists";
 import { useSelector } from "react-redux";
import { selectCurrency } from "../components/Redux/Slices/currencySlice";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";

const SearchScreen = () => {
  const selectedCurrency = useSelector(selectCurrency);
  const navigate = useNavigate();
  const [userAddedCategories, setUserAddedCategories] = useState([]);
  const [Item, setItem] = useState("");
  const [category, setCategory] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [date, setDate] = useState("");
  const [showNoResults, setShowNoResults] = useState(false);
  const handleSearch = async () => {
    let queryParamsString = `/SearchResults?`;
    if (Item) {
      queryParamsString += `Item=${Item}&`;
    }
    if (category) {
      queryParamsString += `category=${category}&`;
    }
    if (minAmount) {
      queryParamsString += `minAmount=${minAmount}&`;
    }
    if (maxAmount) {
      queryParamsString += `maxAmount=${maxAmount}&`;
    }
    if (date) {
      queryParamsString += `date=${date}&`;
    }
    queryParamsString = queryParamsString.slice(0, -1);
    localStorage.setItem(
      "lastSearchDetails",
      JSON.stringify({
        item: Item,
        category,
        minAmount,
        maxAmount,
        date,
      })
    );

    try {
      let results = await db.expenses.toArray();

      if (Item) {
        results = results.filter((result) =>
          result.item.toLowerCase().includes(Item.toLowerCase())
        );
      }
      if (category) {
        results = results.filter((result) => result.category === category);
      }
      if (minAmount) {
        results = results.filter(
          (result) => parseFloat(result.amount) >= parseFloat(minAmount)
        );
      }
      if (maxAmount) {
        results = results.filter(
          (result) => parseFloat(result.amount) <= parseFloat(maxAmount)
        );
      }
      if (date) {
        results = results.filter((result) => result.date === date);
      }
      if (results.length === 0) {
        navigate("/NoResultFound");
        setShowNoResults(true);
      } else {
        setShowNoResults(false);
        navigate(queryParamsString);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleReset = () => {
    setItem("");
    setCategory("");
    setMinAmount("");
    setMaxAmount("");
    setDate("");
    setShowNoResults(false);
  };
  useEffect(() => {
    const fetchUserAddedCategories = async () => {
      try {
        const categoriesFromDB = await db.categories.toArray();
        setUserAddedCategories(categoriesFromDB);
      } catch (error) {
        console.error("Error fetching user-added categories:", error);
      }
    };
    fetchUserAddedCategories();
  }, []);
  useEffect(() => {
    const lastSearchDetails = JSON.parse(
      localStorage.getItem("lastSearchDetails")
    );
    if (lastSearchDetails) {
      const { item, category, minAmount, maxAmount, date } = lastSearchDetails;
      setItem(item);
      setCategory(category);
      setMinAmount(minAmount);
      setMaxAmount(maxAmount);
      setDate(date);
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
  }, []);
  const handleBeforeUnload = () => {
    localStorage.removeItem("lastSearchDetails");
  };

  return (
    <div className="container main-div  ">
      <button
        className="  back-btn  "
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBackCircleOutline />
      </button>
      <h4 className="heading-txt pb-4">Search Expenses</h4>
      <div className=" card child-div  ">
        <div className="text-color  ">
          <div className="row  ">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-2 ">
              <label  className="form-label">
                Item Name
              </label>
              <input
                type="text"
                id="item"
                className="form-control "
                value={Item}
                onChange={(e) => setItem(e.target.value)}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-2 ">
              <label  className="form-label">
                Category
              </label>
              <CategoryLists
                categories={userAddedCategories?.filter(
                  (category) => category.isDeleted !== 1
                )}
                selectedParent={{ id: category }}
                onChange={(e) => setCategory(e.target.value)}
                required
              />
            </div>
            {/* <div className="d-flex "> */}
            <div className=" col-lg-4 col-md-6 col-sm-12 mb-">
              <label  className="form-label noSpace">
                Min Amount
              </label>
              <NumericFormat
                value={minAmount}
                onValueChange={({ floatValue }) => setMinAmount(floatValue)}
                thousandsGroupStyle="lakh"
                thousandSeparator=","
                prefix={selectedCurrency.label}
                className="form-control currencyIcons"
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
              <label  className="form-label noSpace">
                Max Amount
              </label>
              <NumericFormat
                value={maxAmount}
                onValueChange={({ floatValue }) => setMaxAmount(floatValue)}
                thousandsGroupStyle="lakh"
                thousandSeparator=","
                prefix={selectedCurrency.label}
                className="form-control currencyIcons"
              />
            </div>
            {/* </div> */}

            <div className="col-lg-4 col-md-6 col-sm-12 mb-5">
              <label  className="form-label">
                Date
              </label>
              <input
                type="date"
                id="date"
                onFocus={(e) => {
                  e.target.type = "date";
                }}
                className="form-control "
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          </div>
          <div className="search-btn ">
            <button
              className="reset-btn rounded-pill me-3 noSpace"
              onClick={handleReset}
            >
              <span className="material-symbols-outlined verticleAlign fs-5 ">
                clear_all
              </span>

              <span className="verticleAlign "> Reset</span>
            </button>{" "}
            <button
              className="save-btn  rounded-pill noSpace"
              onClick={handleSearch}
            >
              <span className="material-symbols-outlined verticleAlign fs-5 me-1">
                search
              </span>
              <span className="verticleAlign">Search </span>
            </button>{" "}
          </div>
        </div>
      </div>
      {showNoResults && (
        <h4 className="text-danger pt-4 text-center">No result found.</h4>
      )}
    </div>
  );
};

export default SearchScreen;
