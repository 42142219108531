import React from "react";
import { Pagination } from "react-bootstrap";

const ExpensePagination = ({ currentPage, totalPages, paginate }) => {
  return (
    <div className="display-center">
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() =>
            paginate((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1))
          }
        />
        {Array.from(Array(totalPages), (v, i) => (
          <Pagination.Item
            key={i}
            active={i + 1 === currentPage}
            onClick={() => paginate(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() =>
            paginate((nextPage) =>
              nextPage === totalPages ? nextPage : nextPage + 1
            )
          }
        />
      </Pagination>
    </div>
  );
};

export default ExpensePagination;
