import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Login,
  fetchInitialRecord,
  submitInitialRecords,
  GoogleLogin,
} from "../API";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const deviceId = localStorage.getItem("device_id");

  // Login with email and password
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const responseData = await Login(email, password, deviceId);
      if (responseData.status === "success") {
        const {
          access_token: accessToken,
          user_id,
          username,
          source,
          user_membership,
        } = responseData;

        localStorage.setItem("user_membership", user_membership);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("username", username);
        localStorage.setItem("source", source);
        submitInitialRecords(user_id, accessToken);
        setMessage("Successfully logged in");
        localStorage.setItem("email", email || "");
        fetchInitialRecord(accessToken);
        setMessageType("success");
        localStorage.setItem("hasVisited", true);

        setTimeout(() => {
          if (user_membership === "premium") {
            navigate("/Home");
          } else {
            navigate("/create");
          }
          window.location.reload();
        }, 3000);
      } else {
        setMessage(responseData.message);
        setMessageType("error");
      }
    } catch (error) {
      setMessage("Login failed Please try again !");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  //Google Login fetch User info  from URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const user_id = urlParams.get("user_id");
    const username = urlParams.get("username");
    const email = urlParams.get("email");
    const accessToken = urlParams.get("access_token");
    const user_membership = urlParams.get("user_membership");
    const next_billing_date = urlParams.get("next_billing_date");

    if (user_id && username && email && accessToken) {
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("next_billing_date", next_billing_date);
      localStorage.setItem("username", username);
      localStorage.setItem("email", email);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("user_membership", user_membership);
      window.history.pushState({}, document.title, window.location.pathname);
      localStorage.setItem("hasVisited", true);

      setTimeout(() => {
        if (user_membership === "premium") {
          navigate("/Home");
        } else {
          navigate("/create");
        }
        window.location.reload();
      }, 3000);
    }

    const handleInitialRecords = async () => {
      if (accessToken !== null) {
        try {
          setLoading(true);
          await submitInitialRecords(user_id, accessToken);
          await fetchInitialRecord(accessToken);
        } catch (error) {
          console.error("Error processing initial records:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    handleInitialRecords();
  }, []);

  const handleGoogleLogin = async () => {
    window.location.href = GoogleLogin;
  };

  const handleSkip = () => {
    localStorage.setItem("hasVisited", true);
    navigate("/home");
  };

  useEffect(() => {
    const tokenId = localStorage.getItem("accessToken");
    const user_membership = localStorage.getItem("user_membership");

    if (tokenId ) {
      if (user_membership === "premium") {
        navigate("/Home");
      } else {
        navigate("/create");
      }
    }
  }, []);

  const hasvisited = () => {
    localStorage.setItem("hasVisited", true);
  };

  return (
    <>
      <div className="container-fluid bg-white login-main-div p-0 m-0">
        <div className="row login-container">
          <div className="col-lg-6 col-md-6 col-sm-12 card border-0 display-center login-card-img order-lg-1 order-sm-2 order-md-1 order-2 login-card-1">
            <div className="border-0">
              <img
                src="assets/Login-Graphic.svg"
                alt="Login-img"
                className="login-img"
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 display-center order-lg-2 order-md-2 order-sm-1 order-1 login-card-2 child-div ">
            <div className="pb-3">
              <div className="text-center ">
                <img src="assets/App.png" alt="logo" height={150} />
              </div>{" "}
              <h4 className="text-center fw-bold pb-3 ">Paid - It</h4>
              <h6 className="text-center fw-bold pb-2  pt-2">
                Manage Your Daily Expenses
              </h6>
              <h5 className="text-center  fw-bold pb-2 pt-2">Sign-In</h5>
              <form onSubmit={handleLogin}>
                <div className="input-container">
                  <i className="material-symbols-outlined left-icon">mail</i>

                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control mt-4 mb-3 input-with-icon"
                    autoComplete="current-password"
                  />
                </div>

                <div className="input-container">
                  <i className="material-symbols-outlined left-icon">lock</i>

                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control mb-4 input-with-icon"
                    autoComplete="current-password"
                  />

                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>

                <button
                  className="save-btn noSpace rounded-pill w-100 "
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <span className="material-symbols-outlined verticleAlign me-2">
                      login
                    </span>
                  )}

                  {loading ? "Logging in..." : "Login"}
                </button>
              </form>
              <div className="text-center pt-4 pb-3">
                <Link to="/ForgotPassword" onClick={hasvisited}>
                  Forgot Password
                </Link>
              </div>
              <div className="divider-container mb-3">
                <div className="divider"></div>

                <span className="divider-text">OR</span>

                <div className="divider"></div>
              </div>
              <div className="d-flex display-center">
                <button
                  type="button"
                  className="card noSpace p-2  rounded-pill"
                  onClick={handleGoogleLogin}
                >
                  <span className="me-2 ms-2">
                    {" "}
                    <img
                      className="me-2"
                      src="assets/Google.svg"
                      alt="google"
                    />{" "}
                    Login with google
                  </span>
                </button>
              </div>
              <div className="text-center pt-4 pb-4 text-color">
                <Link to="/Signup">Create an account</Link>{" "}
                <div className="noSpace SuccessMessage-div">
                  {message && (
                    <div>
                      {messageType === "success" ? (
                        <div>
                          <span className="material-symbols-outlined   verticleAlign successMessage ">
                            task_alt
                          </span>{" "}
                          <span className="verticleAlign successMessage">
                            {message}
                          </span>
                        </div>
                      ) : (
                        <div className="text-danger">
                          <span className="material-symbols-outlined verticleAlign">
                            error_outline
                          </span>{" "}
                          <span className="verticleAlign"> {message} </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>{" "}
              </div>
            </div>
            <div className="skip-btn " style={{ cursor: "pointer" }}>
              <div onClick={handleSkip}>
                <i className="material-symbols-outlined verticleAlign">
                  skip_next
                </i>

                <span className="verticleAlign">Skip</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
