import React, { useState, useEffect, useMemo } from "react";
import { db } from "../db";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DeleteModal from "../components/DeleteModal";
import { useSelector } from "react-redux";
import { selectCurrency } from "../components/Redux/Slices/currencySlice";
import "../assets/styles/style.css";
import ExpenseLists from "../components/ExpenseLists";
 import { IoChevronBackCircleOutline } from "react-icons/io5";

const SearchResult = () => {
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const selectedCurrency = useSelector(selectCurrency);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultIdToDelete, setSearchResultIdToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const handleEdit = async (id) => {
    try {
      localStorage.setItem("activeRowResult", id);
      navigate(`/edit/${id}`);
    } catch (error) {
      console.error("Error editing expense:", error);
    }
  };
  const handleDelete = async (id) => {
    setSearchResultIdToDelete(id);
    setShowDeleteModal(true);
  };
  const cancelDelete = () => {
    setShowDeleteModal(false);
    setSearchResultIdToDelete(null);
  };
  const confirmDelete = async () => {
    try {
      await db.expenses.update(searchResultIdToDelete, { isDeleted: 1 });
      setSearchResults((prevResults) =>
        prevResults.filter((result) => result.id !== searchResultIdToDelete)
      );
      setSearchResultIdToDelete(null);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting expense:", error);
    }
    setMessage("Successfully Deleted");
    setMessageType("success");

    // setTimeout(() => {
    //   setMessage(null);
    //   setMessageType(null);
    // }, 5000);
  };
  const setActive = (id) => {
    const newActiveRow = activeRow === id ? null : id;
    localStorage.setItem("activeRowResult", newActiveRow);
    setActiveRow(newActiveRow);
  };
  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        let results = await db.expenses.toArray();

        if (queryParams.has("Item")) {
          const Item = queryParams.get("Item").toLowerCase();
          results = results.filter((result) =>
            result.item.toLowerCase().includes(Item)
          );
        }

        if (queryParams.has("category")) {
          const category = queryParams.get("category");
          results = results.filter((result) => result.category === category);
        }

        if (queryParams.has("minAmount")) {
          const minAmount = parseFloat(queryParams.get("minAmount"));
          results = results.filter(
            (result) => parseFloat(result.amount) >= minAmount
          );
        }

        if (queryParams.has("maxAmount")) {
          const maxAmount = parseFloat(queryParams.get("maxAmount"));
          results = results.filter(
            (result) => parseFloat(result.amount) <= maxAmount
          );
        }

        if (queryParams.has("date")) {
          const date = queryParams.get("date");
          results = results.filter((result) => result.date === date);
        }

        setSearchResults(results);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    fetchSearchResults();
  }, [queryParams]);
  useEffect(() => {
    const storedActiveRow = localStorage.getItem("activeRowResult");
    if (storedActiveRow) {
      setActiveRow(parseInt(storedActiveRow));
    }
  }, []);
  window.onbeforeunload = function () {
    localStorage.removeItem("activeRowResult");
  };
  const filteredsearchResults = searchResults?.filter(
    (searchResults) => searchResults.isDeleted !== 1
  );

  return (
    <div className="container main-div  p-2">
       <button
        className="  back-btn  "
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBackCircleOutline />
      </button>
      <h4 className=" heading-txt">Search Results</h4>
      <div className=" child-div card ">
        {filteredsearchResults.length > 0 && (
          <div>
            <ExpenseLists
              filteredExpenses={filteredsearchResults}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              activeRow={activeRow}
              selectedCurrency={selectedCurrency}
              setActive={setActive}
            />
          </div>
        )}{" "}
        <span className=" noSpace SuccessMessage-div ">
          {message && (
            <div
              className={` successMessage ${
                messageType === "success" ? "alert-success" : "alert-danger"
              }`}
            >
              <span className="material-symbols-outlined successMessage">
                task_alt
              </span>
              <span className="successMessage;"> {message} </span>
            </div>
          )}
        </span>
      </div>
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default SearchResult;
