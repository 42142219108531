import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/style.css";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrency,
  setCurrency,
} from "../components/Redux/Slices/currencySlice";
import CountryFlag from "react-country-flag";
import { db } from "../db";

import PaginationEntriesPerPage from "../components/PaginationEntriesPerPage";
import { SyncRecords, Logout, LogoutOTP, ChangeUserName } from "../API";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import CurrencyModal from "../components/CurrencyModal";
import LogoutModal from "../components/LogoutModal";
import SessionExpireModal from "../components/SessionExpireModal";
import SubscriptionPrompt from "../components/SubscriptionPrompt ";

function Settings() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [SyncDisabled, setSyncDisabled] = useState(false);
  const [messageLogout, setMessageLogout] = useState("");
  const [messageType, setMessageType] = useState("");
  const [messageLogoutType, setMessageLogoutType] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPload, setOTPload] = useState("");
  const [sendLogoutOTP, setSendLogoutOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [logoutLoading, setLogoutloading] = useState(false);
  const UserEmail = localStorage.getItem("email");
  const [userName, setUserName] = useState(localStorage.getItem("username"));
  const selectedCurrency = useSelector(selectCurrency);
  const dispatch = useDispatch();
  const subscription_plan = localStorage.getItem("subscription_plan");
  const subscription_status = localStorage.getItem("subscription_status");
  const next_billing_date = localStorage.getItem("next_billing_date");
  const subscription_end_date = localStorage.getItem("subscription_end_date");
  const accessToken = localStorage.getItem("accessToken");
  const source = localStorage.getItem("source");
  const currencyOptions = [
    { code: "INR", label: "₹ ", flag: "IN" },
    { code: "USD", label: "$ ", flag: "US" },
    { code: "AUD", label: "$ ", flag: "AU" },
    { code: "CAD", label: "$ ", flag: "CA" },
  ];
  const [lastSyncTime, setLastSyncTime] = useState(
    localStorage.getItem("lastSyncTime")
  );
  const [totalExpensesCount, setTotalExpensesCount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSessionExpire, setShowSessionExpire] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [temporarySelectedCurrency, setTemporarySelectedCurrency] =
    useState(selectedCurrency);
  const handleCloseConfirmation = () => setShowConfirmation(false);
  const handleCloseLogoutConfirmation = () => {
    setTimerActive(false);
    setShowLogoutConfirmation(false);
  };
  const handleShowConfirmation = () => setShowConfirmation(true);
  const handleCloseSessionExpire = () => setShowSessionExpire(false);
  const handleShowSessionExpire = () => setShowSessionExpire(true);
  const handleShowLogoutConfirmation = () => setShowLogoutConfirmation(true);
  const [timeLeft, setTimeLeft] = useState(600);
  const [timerActive, setTimerActive] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    const storedValue = localStorage.getItem("itemsPerPage") || 10;
    return parseInt(storedValue);
  });
  const handleCurrencyChange = (currency) => {
    setTemporarySelectedCurrency(currency);
  };

  const handleConfirmCurrencyChange = () => {
    handleCloseConfirmation();
    if (temporarySelectedCurrency) {
      localStorage.setItem(
        "selectedCurrency",
        JSON.stringify(temporarySelectedCurrency)
      );
      dispatch(setCurrency(temporarySelectedCurrency));
    }
    clearExpensesFromDB();
    setMessage("Successfully updated");
    setMessageType("success");
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    if (temporarySelectedCurrency !== selectedCurrency) {
      handleShowConfirmation();
    } else {
      setMessage("Successfully updated");
      setMessageType("success");
    }
    localStorage.setItem("itemsPerPage", itemsPerPage.toString());
    ChangeUserName(userName);
  };

  const clearExpensesFromDB = () => {
    db.expenses
      .toCollection()
      .modify({ isDeleted: 1 })
      .then(() => {
        console.log("Expenses cleared from IndexedDB");
      })
      .catch((error) => {
        console.error("Error clearing expenses from IndexedDB:", error);
      });
  };

  const handleLogout = () => {
    handleShowLogoutConfirmation();
  };

  const changeItemsPerPage = (event) => {
    const value = event.target.value;
    if (value === "All") {
      setItemsPerPage(totalExpensesCount);
    } else {
      const intValue = parseInt(value);
      setItemsPerPage(intValue);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  function formatTimeElapsed(lastSyncTime) {
    const now = new Date();
    const syncTime = new Date(lastSyncTime);
    const timeDiff = now.getTime() - syncTime.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days >= 2) {
      return ` ${syncTime.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })}`;
    } else if (days >= 1) {
      return "yesterday";
    } else if (hours >= 1) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes >= 1) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  }

  const updateLastSyncTime = () => {
    const now = new Date();
    const formattedTime = now.toLocaleString();
    setLastSyncTime(formattedTime);
    localStorage.setItem("lastSyncTime", formattedTime);
  };

  function handleSync() {
    setLoading(true);
    setSyncDisabled(true);
    setTimeout(() => {
      setSyncDisabled(false);
    }, 60000);
    SyncRecords()
      .then(() => {
        setLoading(false);
        updateLastSyncTime();
        setMessage("Data imported successfully ");
        setMessageType("success");
      })
      .catch((error) => {
        console.error("Error syncing data:", error);
        setLoading(false);
      });
  }

  const handleConfirmLogout = async () => {
    try {
      setLogoutloading(true);
      const response = await Logout();
      console.log("response.status", response.status);
      if (response.status === "success") {
        setLogoutloading(false);
        setMessageLogout("OTP has been sent to your registered email.");
        setMessageLogoutType("success");
        setSendLogoutOTP(response.status);
        handleCloseSessionExpire();
        handleShowLogoutConfirmation();
        startTimer();
      } else {
        setLogoutloading(false);
        setSendLogoutOTP(response.status);
        setMessageLogout("Logout failed.");
        setMessageLogoutType("success");
      }
    } catch (error) {
      setLogoutloading(false);
      setSendLogoutOTP("error");
      setMessageLogout("Logout failed");
      setMessageLogoutType("error");
      console.error("Logout error:", error);
    }
  };

  const handleLogoutOnly = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("hasVisited");
    localStorage.removeItem("source");
    setMessageLogoutType("success");
    setMessageLogout("Successfully Logout");
    setTimeout(() => {
      navigate("/");
    }, 3000);
  };

  const handlelogoutOTP = async () => {
    try {
      setOTPload(true);
      const response = await LogoutOTP(OTP);
      if (response.status === "success") {
        setOTPload(false);
        setMessageLogout(response.message);
        setMessageLogoutType("success");
        handleLogoutOnly();
        clearIndexedDB();
      } else {
        setOTPload(false);
        setMessageLogout(response.message);
        setMessageLogoutType("error");
      }
    } catch (error) {
      setOTPload(false);
      setMessageLogoutType("error");
      setMessageLogout(error.message);
    }
  };

  useEffect(() => {
    db.expenses
      .count()
      .then((count) => {
        setTotalExpensesCount(count);
      })
      .catch((error) => {
        console.error("Error fetching total expenses count:", error);
      });
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const startTimer = () => {
    setTimeLeft(600);
    setTimerActive(true);
  };

  useEffect(() => {
    let timer;
    if (timerActive && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      handleCloseLogoutConfirmation();
      setTimerActive(false);
      handleShowSessionExpire();
      setMessageLogoutType("error");
      setMessageLogout("Session expired please try again ! ");
    }
    return () => clearTimeout(timer);
  }, [timerActive, timeLeft]);

  const handleResentOTP = () => {
    handleConfirmLogout();
  };

  const clearIndexedDB = async () => {
    try {
      await db.transaction("rw", db.expenses, db.categories, async () => {
        await db.expenses.clear();
        await db.categories.clear();
      });
      console.log("Database cleared successfully.");
    } catch (error) {
      console.error("Failed to clear the database:");
    }
  };

  // Generate current date
  const current_date = new Date();
  const checkEndDate = new Date(subscription_end_date);
   const enablePremium = () => {
    localStorage.setItem("isPremium", "true");
    navigate("/Signup");
  };

  return (
    <div className="container main-div">
      <button
        className="  back-btn  "
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBackCircleOutline />
      </button>
      <h4 className="heading-txt pb-4">Settings </h4>

      <div className="card child-div  ">
        <form onSubmit={handleSubmit}>
          <div className="row mb-5">
            {accessToken && (
              <div className="col settingCol  mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  value={userName}
                  required
                  onChange={(e) => setUserName(e.target.value)}
                  className="form-control"
                />
              </div>
            )}
            <div className="col settingCol  mb-4">
              <label className="form-label noSpace">Change Currency</label>
              <Dropdown className="currencyIcons">
                <Dropdown.Toggle
                  className="button-div w-100 p-2 form-control display-between"
                  variant="light"
                  id="currency-dropdown "
                >
                  {temporarySelectedCurrency ? (
                    <>
                      {" "}
                      <span>
                        <CountryFlag
                          countryCode={temporarySelectedCurrency.flag}
                          svg
                        />
                        <span className="ms-3">
                          {" "}
                          {temporarySelectedCurrency.code}{" "}
                          {temporarySelectedCurrency.label}
                        </span>
                      </span>
                    </>
                  ) : (
                    "Select Currency"
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100 form-control ">
                  {currencyOptions.map((currency) => (
                    <Dropdown.Item
                      key={`${currency.code}`}
                      onClick={() => handleCurrencyChange(currency)}
                      active={selectedCurrency?.code === currency?.code}
                    >
                      <CountryFlag countryCode={currency.flag} svg />
                      <span className="ms-3  ">{currency?.code} </span>
                      {currency?.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col settingCol  mb-4">
              <label className="form-label noSpace">Entries Per Page</label>
              <PaginationEntriesPerPage
                value={itemsPerPage}
                totalCount={totalExpensesCount}
                onChange={changeItemsPerPage}
              />
            </div>{" "}
            {subscription_plan !== "premium" && (
              <div className="col settingCol mb-4">
                <label className="form-label noSpace">Go Premium</label>
                <div>
                  <button
                    onClick={enablePremium}
                    className="btn btn-success noSpace"
                  >
                    <span className="material-symbols-outlined verticleAlign">
                      license
                    </span>
                    <span className="verticleAlign ms-1">Sign Up</span>
                  </button>
                </div>
              </div>
            )}
            {/* For premium content */}
            {accessToken && (
              <div className="premiumContent ">
                {accessToken && (
                  <div>
                    {source === "internal" && (
                      <div className="col settingCol  mb-4 pb-4">
                        <label className="form-label noSpace">
                          Change Password
                        </label>
                        <br />
                        <button
                          className="loginSetting-btn pt-2 pb-2"
                          onClick={() => navigate("/ChangePassword")}
                        >
                          <span className="verticleAlign material-symbols-outlined">
                            lock_reset
                          </span>
                          <span className="ms-2 verticleAlign">Reset</span>
                        </button>
                      </div>
                    )}

                    <div className="col settingCol   mb-3 pb-3 ">
                      <label className="form-label pb-1 noSpace">
                        Sync Expenses
                      </label>
                      {loading ? (
                        <div className=" ">
                          <div
                            className="ms-3 spinner-border text-success"
                            role="status"
                          ></div>
                        </div>
                      ) : (
                        <div className=" ">
                          <button
                            className={`  ${
                              SyncDisabled
                                ? "btn btn-secondary "
                                : "loginSetting-btn pt-2 pb-2 "
                            }`}
                            onClick={handleSync}
                            disabled={SyncDisabled}
                          >
                            <span className="material-symbols-outlined verticleAlign">
                              sync
                            </span>{" "}
                            <span className="me-2  verticleAlign ">Sync</span>
                          </button>
                          <p className="pt-2 text-start  text-secondary">
                            {SyncDisabled ? (
                              <span>
                                Next sync will be available in 1 minute
                              </span>
                            ) : (
                              <span>
                                Last Sync:
                                {lastSyncTime
                                  ? formatTimeElapsed(lastSyncTime)
                                  : "Never"}
                              </span>
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col settingCol  mb-4 pb-4">
                  <label className="form-label noSpace">Tools</label>
                  <div>
                    <button
                      onClick={() => navigate("/Admin")}
                      className="loginSetting-btn  pt-2 pb-2 "
                    >
                      <span className="material-symbols-outlined verticleAlign">
                        shield_person
                      </span>
                      <span className="ms-2  verticleAlign ">Admin</span>
                    </button>
                  </div>
                </div>
                {accessToken && (
                  <div>
                    <div className="col settingCol  mb-4 pb-4   ">
                      <div className=" ">
                        <label className="form-label noSpace">
                          Current Plan
                        </label>
                        <div className="text-success">Premium</div>
                      </div>
                    </div>
                    {subscription_status === "active" && (
                      <div className="col settingCol    mb-3 pb-3 ">
                        <label className="form-label noSpace">
                          Subscription:
                        </label>
                        <div className="text-success "> Active </div>
                      </div>
                    )}
                    <div className="col settingCol    mb-3 pb-3 ">
                      <div className="SubscriptionDate ">
                        {current_date >= checkEndDate ? (
                          <div className=" col settingCol pt-2 pb-4">
                            <div className="">
                              {" "}
                              <label className="form-label  fw-bolder">
                                Next Billing Date:{" "}
                              </label>{" "}
                              <div className="text-secondary  noSpace">
                                {next_billing_date}
                              </div>
                            </div>
                            <span className="text-danger ">
                              {" "}
                              <SubscriptionPrompt
                                expiryDate={next_billing_date}
                              />
                            </span>{" "}
                          </div>
                        ) : (
                          <div className="mb-4">
                            <label className=" form-label text-danger">
                              Subscription Expired on
                            </label>
                            <span className=" me-2 text-secondary noSpace">
                              {subscription_end_date}{" "}
                            </span>
                            <button className="btn btn-warning ms-2">
                              Renew Now
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="col settingCol  mb-5 ">
                  <div className="pb-5">
                    <label className="form-label pb-1 noSpace">
                      Linked Email
                    </label>
                    <div>
                      {accessToken ? (
                        <h6 className="text-black">{UserEmail}</h6>
                      ) : (
                        <button
                          className="loginSetting-btn noSpace"
                          onClick={() => navigate("/")}
                        >
                          <span className="material-symbols-outlined verticleAlign">
                            login
                          </span>
                          <span className="ms-2">Login</span>
                        </button>
                      )}
                    </div>
                    {accessToken && (
                      <div className="position-relative">
                        <div>
                          <button
                            className="hover-text-logout  btn-danger btn"
                            onClick={handleLogout}
                          >
                            <span className="material-symbols-outlined verticleAlign">
                              logout
                            </span>
                            <span className="me-2">Logout </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <button
            type="submit"
            className="save-btn button rounded-pill "
            onClick={handleSaveClick}
          >
            <span className="material-symbols-outlined verticleAlign me-2">
              save
            </span>
            <span className="verticleAlign">Save</span>
          </button>
        </form>
        <div className=" noSpace SuccessMessage-div ">
          {message && (
            <div
              className={` successMessage ${
                messageType === "success" ? "alert-success" : "alert-danger"
              }`}
            >
              <span className="material-symbols-outlined successMessage">
                task_alt
              </span>
              <span className="successMessage;"> {message} </span>
            </div>
          )}
        </div>
      </div>

      <CurrencyModal
        show={showConfirmation}
        handleClose={handleCloseConfirmation}
        handleConfirm={handleConfirmCurrencyChange}
        selectedCurrency={selectedCurrency}
        temporarySelectedCurrency={temporarySelectedCurrency}
      />
      <LogoutModal
        show={showLogoutConfirmation}
        handleLogoutOnly={handleLogoutOnly}
        handleConfirm={handleConfirmLogout}
        messageLogoutType={messageLogoutType}
        messageLogout={messageLogout}
        handleClose={handleCloseLogoutConfirmation}
        logoutLoading={logoutLoading}
        sendLogoutOTP={sendLogoutOTP}
        OTP={OTP}
        setOTP={setOTP}
        OTPload={OTPload}
        OTPVerify={handlelogoutOTP}
        timerActive={timerActive}
        formatTime={formatTime}
        timeLeft={timeLeft}
      />
      <SessionExpireModal
        show={showSessionExpire}
        loading={logoutLoading}
        handleResentOTP={handleResentOTP}
      />
    </div>
  );
}

export default Settings;
