import React from "react";

function FAQ() {
  return (
    <div className="container-fluid bg-light bg-FAQ">
      <div className="container ">
        <div className=" ">
          <div
            className=" li-4  display-between p-4 "
            style={{
              backgroundColor: "#f5e02880",
              borderBottomLeftRadius: "80px",
              borderBottomRightRadius: "80px",
            }}
          >
            <a href="/#/landingScreen">
              {" "}
              <p className="fs-3 fw-bolder "> Home</p>
            </a>{" "}
            <img src="assets/App.png " alt="Login-img" height={100} />
            <p className="fs-3 fw-bolder "> Support</p>
          </div>
          <div className="text-center pt-4 fw-bolder fs-3 ">
            {"  "}
            <p>Paid-It</p>
            <p>Manage your daily expenses</p>{" "}
          </div>
          <div className=" display-center ">
            <img
              src="assets/images/FAQ.svg "
              alt="Login-img"
              // height='50%'
              width="70%"
            />{" "}
          </div>

          <div className=" display-center">
            <div class="container p-4 bg-light">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item rounded-3 border-0 shadow mb-2">
                  <h2 class="accordion-header  accordion-FAQ">
                    <button
                      class="accordion-button border-bottom collapsed fw-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Bootstrap Accordion #1
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <p>Please check our more latest Design @ </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item rounded-3 border-0 shadow mb-2">
                  <h2 class="accordion-header accordion-FAQ">
                    <button
                      class="accordion-button border-bottom collapsed fw-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Bootstrap Accordion #2
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <p>Please check our more latest Design @</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item rounded-3 border-0 mb-2 shadow">
                  <h2 class="accordion-header  accordion-FAQ">
                    <button
                      class="accordion-button border-bottom collapsed fw-semibold"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Bootstrap Accordion #3
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      <p>Please check our more latest Design @ </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="fw-bolder display-center pt-4  noSpace">
            <p>© {new Date().getFullYear()} Webstrong Solutions.</p>
            <p>
              {/* Knowledge Base <span className="ms-3"> Privacy Policy</span> */}
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
