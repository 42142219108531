import React from "react";
import { Modal } from "react-bootstrap";

function CurrencyModal({
  show,
  handleClose,
  handleConfirm,
  selectedCurrency,
  temporarySelectedCurrency,
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Currency Changed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          You are trying to change the currency from{" "}
          {selectedCurrency ? (
            <span>{selectedCurrency.code}</span>
          ) : (
            "Select Currency"
          )}{" "}
          to{" "}
          {temporarySelectedCurrency ? (
            <>{temporarySelectedCurrency.code}</>
          ) : (
            "Select Currency"
          )}
          .
          <br />
          Would you like to remove the existing expenses in{" "}
          {selectedCurrency ? <>{selectedCurrency.code}</> : "Select Currency"}
          ?
          <br />
          <br />
          Click '<span className="fw-bold">Yes</span>' to remove expenses or '
          <span className="fw-bold">No</span>' to keep them in{" "}
          {selectedCurrency ? (
            <span>{selectedCurrency.code}</span>
          ) : (
            "Select Currency"
          )}
          .
        </div>
      </Modal.Body>
      <div>
        <div className="d-flex justify-content-end align-items-center  p-2">
          <div>
            <button className="btn btn-warning me-3" onClick={handleClose}>
              No
            </button>{" "}
            <button className="btn btn-danger " onClick={handleConfirm}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CurrencyModal;
