import React from "react";

const PaginationEntriesPerPage = ({ value, onChange, totalCount }) => {
  const itemsPerPageOptions = [10, 25, 50, 100];

  return (
    <div>
      <select
        className="form-select form-control mb-3  w-100 button-div"
        value={value}
        onChange={onChange}
      >
        {itemsPerPageOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
        <option value={totalCount}>All </option>
      </select>
    </div>
  );
};

export default PaginationEntriesPerPage;
