import Dexie from "dexie";

export const db = new Dexie("Paid-It");
db.version(1).stores({
  expenses:
    "++id, item, quantity, amount, currency, date, userId, category, isDeleted, isEdited",
  categories: "++id, name, parentId, userId, isDeleted, isEdited",
});

export default db;
