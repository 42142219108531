import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Register, GoogleLogin } from "../API";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Signup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await Register(username, email, password);

      if (response.status === "success") {
        console.log("Registration response:", response.data);
        setMessage(
          <>
            Your account has been successfully created <br />
            Please check your email and confirm your account to log in.
          </>
        );
        setMessageType("success");
      } else {
        setMessage(response.message);
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error registering:", error);
      setMessage("Signup failed Please try again !");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = GoogleLogin;
  };

  const handleSkip = () => {
    navigate("/Home");
  };
  // const premiumStatus = localStorage.getItem("isPremium");

  // useEffect(() => {
  //   if (premiumStatus === "false") {
  //     navigate("/Home");
  //   }
  // });

  return (
    <>
      <div className="container-fluid login-container bg-white">
        <div className="row login-container">
          <div className="col-lg-6 col-md-6 col-sm-12 card border-0 display-center login-card-img order-lg-1 order-sm-2 order-md-1 order-2 login-card-1">
            <div className="border-0">
              <img
                src="assets/Login-Graphic.svg"
                alt="Login-img"
                className="login-img"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 display-center order-lg-2 order-md-2 order-sm-1 order-1 login-card-2 child-div">
            <div className="pb-3">
              <div className="text-center ">
                <img src="assets/App.png" alt="logo" height={150} />
              </div>{" "}
              <h4 className="text-center fw-bold pb-3 ">Paid - It</h4>
              <h6 className="text-center fw-bold pb-2  pt-2">
                Manage Your Daily Expenses
              </h6>
              <h5 className="text-center fw-bold pb-2 pt-2">
                Create an account
              </h5>
              <form onSubmit={handleRegister}>
                <div className="d-block">
                  <div className="input-container">
                    <i className="material-symbols-outlined left-icon">
                      person
                    </i>
                    <input
                      type="text"
                      placeholder="User Name"
                      value={username}
                      required
                      onChange={(e) => setUsername(e.target.value)}
                      className="form-control mb-4 input-with-icon"
                      autoComplete="current-password"
                    />
                  </div>
                  <div className="input-container">
                    <i className="material-symbols-outlined left-icon">mail</i>
                    <input
                      type="email"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control mb-4 input-with-icon"
                      autoComplete="current-password"
                    />
                  </div>
                  <div className="input-container">
                    <i className="material-symbols-outlined left-icon">lock</i>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control mb-4 input-with-icon"
                      autoComplete="current-password"
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="save-btn noSpace rounded-pill w-100  "
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border  spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <span className="material-symbols-outlined verticleAlign me-2">
                        how_to_reg
                      </span>
                    )}
                    {loading ? "Registering..." : "Register"}
                  </button>
                </div>
              </form>
              <div className="divider-container mt-3 mb-3">
                <div className="divider"></div>
                <span className="divider-text">OR</span>
                <div className="divider"></div>
              </div>
              <div className="d-flex display-center ">
                <button
                  type="button"
                  className="card noSpace p-2 rounded-pill"
                  onClick={handleGoogleLogin}
                >
                  <span className="me-2 ms-2">
                    {" "}
                    <img
                      className="me-2"
                      src="assets/Google.svg"
                      alt="google"
                    />{" "}
                    Login with google
                  </span>
                </button>
              </div>
              <div className="text-center pt-4 pb-4">
                <Link to="/">Already have an account</Link>
                <div className="noSpace SuccessMessage-div">
                  {message && (
                    <div>
                      {messageType === "success" ? (
                        <div>
                          <span className="material-symbols-outlined   verticleAlign successMessage ">
                            task_alt
                          </span>{" "}
                          <span className="verticleAlign successMessage">
                            {message}
                          </span>
                        </div>
                      ) : (
                        <div className="text-danger">
                          <span className="material-symbols-outlined verticleAlign">
                            error_outline
                          </span>{" "}
                          <span className="verticleAlign"> {message} </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>{" "}
              </div>
            </div>
            <div className=" skip-btn" style={{ cursor: "pointer" }}>
              <div onClick={handleSkip}>
                <i className="material-symbols-outlined verticleAlign">
                  skip_next
                </i>
                <span className="verticleAlign">Skip</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
