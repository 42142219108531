import React from "react";
 
const ExpenseSorting = ({ sortBy, sortOrder, handleSort }) => {
  return (
    <thead className="mb-3">
      <tr className="search-tableHead ">
        <th className="hide-sm-screen">#</th>
        <th onClick={() => handleSort("date")}>
          Date{" "}
          {sortBy === "date" &&
            (sortOrder === "asc" ? <span className="material-symbols-outlined">
arrow_upward
</span> : <span className="material-symbols-outlined">
arrow_downward
</span>)}
        </th>
        <th onClick={() => handleSort("item")}>
          Item{" "}
          {sortBy === "item" &&
            (sortOrder === "asc" ? <span className="material-symbols-outlined">
arrow_upward
</span> : <span className="material-symbols-outlined">
arrow_downward
</span>)}
        </th>
        <th className="hide-sm-screen" onClick={() => handleSort("category")}>
          Category{" "}
          {sortBy === "category" &&
            (sortOrder === "asc" ? <span className="material-symbols-outlined">
arrow_upward
</span> : <span className="material-symbols-outlined">
arrow_downward
</span>)}
        </th>
        <th
          className=" text-center hide-sm-screen"
          onClick={() => handleSort("quantity")}
        >
          Quantity{" "}
          {sortBy === "quantity" &&
            (sortOrder === "asc" ? <span className="material-symbols-outlined">
arrow_upward
</span> : <span className="material-symbols-outlined">
arrow_downward
</span>)}
        </th>
        <th className="text-end" onClick={() => handleSort("amount")}>
          Amount{" "}
          {sortBy === "amount" &&
            (sortOrder === "asc" ? <span className="material-symbols-outlined">
arrow_upward
</span> : <span className="material-symbols-outlined">
arrow_downward
</span>)}
        </th>
        <th className="text-center">Action</th>
      </tr>
    </thead>
  );
};

export default ExpenseSorting;
