import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { Forgot, ForgotOTP } from "../API";
import SessionExpireModal from "../components/SessionExpireModal";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [sendForgetOTP, setSendForgetOTP] = useState("");
  const [ForgetOTP, setForgetOTP] = useState("");
  const [OTPload, setOTPload] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600);
  const [timerActive, setTimerActive] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const forgotToken = localStorage.getItem("forgotToken");
  const [showSessionExpire, setShowSessionExpire] = useState(false);
  const handleCloseSessionExpire = () => setShowSessionExpire(false);
  const handleShowSessionExpire = () => setShowSessionExpire(true);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      setloading(true);
      const response = await Forgot(email);
      if (response?.status === "success") {
        setSendForgetOTP(response.status);
        const { forgotToken } = response;
        localStorage.setItem("forgotToken", forgotToken);
        setMessage("OTP has been sent to your email address.");
        setMessageType("success");
        setErrorMessage("");
        setloading(false);
        startTimer();
        handleCloseSessionExpire();
      } else {
        setErrorMessage(response.message || "An error occurred");
        setMessage("");
        setSendForgetOTP(response.status);
        setMessageType("error");
        setloading(false);
      }
    } catch (error) {
      console.error("Error handling forgot password:", error);
      setErrorMessage(error.message || "An error occurred");
      setMessageType("error");
      setSendForgetOTP("error");
      setloading(false);
    }
  };

  const handleForgotOTP = async (e) => {
    e.preventDefault();
    try {
      setOTPload(true);
      const response = await ForgotOTP(
        forgotToken,
        ForgetOTP,
        newPassword,
        confirmPassword
      );

      if (response.status === "success") {
        setOTPload(false);
        setMessage("Successfully changed password.");
        setMessageType("success");
        setErrorMessage([]);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        setOTPload(false);
        if (typeof response.message === "object") {
          const messages = [];
          for (const key in response.message) {
            messages.push(...response.message[key]);
          }
          setErrorMessage(messages);
        } else {
          setErrorMessage([response.message]);
        }
        setMessage("");
        setMessageType("error");
      }
    } catch (error) {
      setOTPload(false);
      setErrorMessage([error.message]);
      setMessage("");
      setMessageType("error");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const startTimer = () => {
    setTimeLeft(600);
    setTimerActive(true);
  };

  useEffect(() => {
    let timer;
    if (timerActive && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      handleShowSessionExpire();
      setMessageType("error");
      setErrorMessage("");
      setTimerActive(false);
    }
    return () => clearTimeout(timer);
  }, [timerActive, timeLeft]);

  const handleResentOTP = () => {
    handleForgotPassword();
  };

  const premiumStatus = localStorage.getItem("isPremium");

  useEffect(() => {
    if (premiumStatus === "false") {
      navigate("/Home");
    }
  });

  const hasvisited = () => {
    localStorage.setItem("hasVisited", false);
    navigate(-1);
  };
  return (
    <div className="container main-div ">
      <button className="back-btn" onClick={hasvisited}>
        <IoChevronBackCircleOutline />
      </button>
      <h4 className="heading-txt mb-3">Forgot Password</h4>

      <div className=" card child-div">
        <div>
          {sendForgetOTP === "success" ? (
            <div className="resetpassword">
              <form onSubmit={handleForgotOTP}>
                <div className="mb-3">
                  <label className="pb-2">New password</label>

                  <div className="input-container">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      id="newPassword"
                      className="mb-4 form-control input-with-icon"
                      value={newPassword}
                      required
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="password-toggle-btn "
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="pb-2">Confirm password</label>

                  <div className="input-container">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      className="mb-4 form-control input-with-icon"
                      value={confirmPassword}
                      required
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <span
                      type="button"
                      className="password-toggle-btn"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="pb-2">One time password</label>
                  <input
                    type="number"
                    placeholder="Enter verification OTP"
                    value={ForgetOTP}
                    required
                    onChange={(e) =>
                      setForgetOTP(
                        e.target.value.replace(/\D/g, "").slice(0, 6)
                      )
                    }
                    className="form-control "
                  />
                </div>

                <button
                  className="save-btn button rounded-pill "
                  disabled={OTPload}
                  type="submit"
                >
                  {OTPload ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <span className="material-symbols-outlined verticleAlign me-1">
                      lock_reset
                    </span>
                  )}
                  <span className="verticleAlign ">
                    {OTPload ? "Reseting..." : "Reset"}
                  </span>
                </button>
              </form>
            </div>
          ) : (
            <div className="emailSend">
              <form className="form  " onSubmit={handleForgotPassword}>
                <label className="text-color pb-2" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className=" form-control"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p>An OTP will be send to your email.</p>

                <button className="save-btn button rounded-pill" type="submit">
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <span className="material-symbols-outlined verticleAlign me-1">
                      send
                    </span>
                  )}
                  <span className="verticleAlign ">
                    {" "}
                    {loading ? "Sending..." : "Send OTP"}{" "}
                  </span>
                </button>
              </form>
            </div>
          )}
        </div>

        <div>
          {timerActive && (
            <div className="timer text-danger ">
              <p style={{ fontSize: "14px" }}>
                OTP will expire in:{" "}
                <span className="fw-bold">{formatTime(timeLeft)}</span> minutes.
              </p>
            </div>
          )}
        </div>
        <div>
          {messageType === "success" ? (
            <div>
              {message && (
                <div className=" d-flex text-success">
                  <span className="material-symbols-outlined  verticleAlign me-2 ">
                    task_alt
                  </span>{" "}
                  <div className=" verticleAlign "> {message} </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              {errorMessage.length > 0 && (
                <div className="text-danger">
                  {errorMessage.map((msg, index) => (
                    <div key={index}>
                      {msg.split(". ").map((line, idx) => (
                        <div className="d-flex " key={idx}>
                          <span className="material-symbols-outlined verticleAlign me-2">
                            error_outline
                          </span>
                          <span className="verticleAlign"> {line} </span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <SessionExpireModal
        show={showSessionExpire}
        loading={loading}
        handleResentOTP={handleResentOTP}
      />
    </div>
  );
};

export default ForgetPassword;
