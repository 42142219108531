import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { ChangePasswordAPI } from "../API";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage("New passwords do not match");
      setMessageType("error");
      return;
    }

    try {
      setLoading(true);
      const response = await ChangePasswordAPI(
        currentPassword,
        newPassword,
        confirmPassword
      );

      if (response.status === "success") {
        setMessage("Successfully changed password.");
        setMessageType("success");
        setErrorMessage([]);
        setLoading(false);
        localStorage.removeItem("user_id");
        localStorage.removeItem("email");
        localStorage.removeItem("accessToken");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        if (typeof response.message === "object") {
          const messages = [];
          for (const key in response.message) {
            messages.push(...response.message[key]);
          }
          setErrorMessage(messages);
        } else {
          setErrorMessage([response.message]);
        }
        setMessage("");
        setMessageType("error");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error changing password:", error);
      setMessage("An error occurred");
      setMessageType("error");
    }
  };

  const premiumStatus = localStorage.getItem("isPremium");

  useEffect(() => {
    if (premiumStatus === "false") {
      navigate("/Home");
    }
  });

  return (
    <div className="container main-div p-2">
      <button
        className="back-btn"
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBackCircleOutline />
      </button>
      <h4 className="heading-txt mb-3">Change Password</h4>

      <form onSubmit={handleChangePassword} className="card child-div">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
            <label className="text-color pb-2" htmlFor="currentPassword ">
              Current Password
            </label>
            <div className="input-container">
              <input
                type={showCurrentPassword ? "text" : "password"}
                id="currentPassword"
                className="mb-4 form-control input-with-icon"
                value={currentPassword}
                required
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              >
                {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
            <label className="text-color pb-2" htmlFor="newPassword">
              New Password
            </label>
            <div className="input-container">
              <input
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                className="mb-4 form-control input-with-icon"
                value={newPassword}
                required
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
            <label className="text-color pb-2" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <div className="input-container">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                className="mb-4 form-control input-with-icon"
                value={confirmPassword}
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span
                type="button"
                className="password-toggle-btn"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
        </div>
        <button
          className="save-btn button rounded-pill  "
          disabled={loading}
          type="submit"
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <span className="material-symbols-outlined verticleAlign me-2">
              lock_reset
            </span>
          )}

          {loading ? "Changing..." : "Reset"}
        </button>

        <div>
          {messageType === "success" ? (
            <div className=" ">
              {message && (
                <div className="successMessage d-flex ">
                  <span className="material-symbols-outlined  verticleAlign me-2 ">
                    task_alt
                  </span>{" "}
                  <div className=" verticleAlign "> {message} </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              {errorMessage.length > 0 && (
                <div className="text-danger">
                  {errorMessage.map((msg, index) => (
                    <div key={index}>
                      {msg.split(". ").map((line, idx) => (
                        <div className="d-flex " key={idx}>
                          <span className="material-symbols-outlined verticleAlign me-2 ">
                            error_outline
                          </span>
                          <span className="verticleAlign "> {line} </span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
