import React, { useEffect, useState } from "react";
import {
  HashRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import AddExpenses from "./pages/AddExpenses";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Signup from "./pages/Signup";
import EditExpense from "./pages/EditExpense";
import SearchScreen from "./pages/SearchScreen";
import DetailsScreen from "./pages/DetailsScreen";
import Navbar from "./pages/Navbar";
import Settings from "./pages/Settings";
import ManageCategories from "./pages/ManageCategories";
import AddCategory from "./pages/AddCategory";
import EditCategory from "./pages/EditCategory";
import SearchResult from "./pages/SearchResult";
import Admin from "./pages/Admin";
import FourZeroFour from "./pages/FourZeroFour";
import NoResultFound from "./pages/NoResultFound";
import {
  SyncRecords,
  fetchChangedRecords,
  fetchSubscriptionStatus,
} from "./API";
import { v4 as uuidv4 } from "uuid";
import ChangePassword from "./pages/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword";
import Create from "./pages/Create";
import LandingScreen from "./pages/LandingScreen";
import FAQ from "./pages/FAQ";
import { useDispatch, useSelector } from "react-redux";

const App = () => {
  const accessToken = localStorage.getItem("accessToken");
   const [loading, setLoading] = useState(false);
  const fetchIntervalSequence = [10000, 30000, 90000, 180000, 300000];
  const dispatch = useDispatch();
  const subscription_status_plan = useSelector(
    (state) => state.subscription.status
  );

  //Sync and fetch record regularly by time period
  const syncAndFetchRecords = async (index = 0) => {
     try {
      if (subscription_status_plan === "active") {
        await SyncRecords(accessToken);
        await fetchChangedRecords(accessToken);
      }
    } catch (error) {
      console.error("Error during sync and fetch operations:", error);
    }
    let nextIndex = index + 1;
    if (nextIndex >= fetchIntervalSequence.length) {
      nextIndex = fetchIntervalSequence.length - 1;
    }
    const nextInterval = fetchIntervalSequence[nextIndex];
    setTimeout(() => {
      syncAndFetchRecords(nextIndex);
    }, nextInterval);
  };

  setTimeout(() => {
    syncAndFetchRecords();
  }, 10000);

  useEffect(() => {
    const deviceId = localStorage.getItem("device_id") || uuidv4();
    localStorage.setItem("device_id", deviceId);
  }, []);

  const [landingShow, setLandingShow] = useState(
    localStorage.getItem("landingShow") === "true"
  );

 

  //Subscritpion status // Fetch and store subscription status
  useEffect(() => {
    const fetchStatus = fetchSubscriptionStatus(dispatch);

    fetchStatus();

    const interval = setInterval(fetchStatus, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, [dispatch]);


  const AppContent = () => {
    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
      const hasVisited = localStorage.getItem("hasVisited");
      if (hasVisited === false) {
        navigate("/");
      }
    }, [navigate]);

    return (
      <>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 9999,
            }}
          >
            <div
              className="spinner-border text-color fs-5"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            <span className="ms-3 fw-bold text-color fs-4">Loading...</span>
          </div>
        ) : (
          <div>
            {location.pathname !== "/" &&
              location.pathname !== "/FAQ" &&
              location.pathname !== "/LandingScreen" &&
              location.pathname !== "/ForgotPassword" &&
              location.pathname !== "/Signup" && <Navbar />}
            <Routes>
              <Route path="/Home" exact element={<Home />} />
              <Route path="/AddExpenses" exact element={<AddExpenses />} />
              <Route path="/Admin" element={<Admin />} />
              <Route path="/SearchScreen" element={<SearchScreen />} />
              <Route
                path="/LandingScreen"
                element={<LandingScreen setLandingShow={setLandingShow} />}
              />
              <Route path="/FAQ" element={<FAQ />} />
              <Route path="/edit/:expenseId" element={<EditExpense />} />
              <Route path="/ManageCategories" element={<ManageCategories />} />
              <Route
                path="/editcategory/:categoryId"
                element={<EditCategory />}
              />
              <Route path="*" element={<FourZeroFour />} />
              <Route path="/NoResultFound" element={<NoResultFound />} />
              <Route path="/Settings" element={<Settings />} />
              <Route path="/Create" element={<Create />} />
              <Route path="/SearchResults" element={<SearchResult />} />
              <Route
                path="/details/:category/:selectedFilter"
                element={<DetailsScreen />}
              />
              <Route path="/AddCategory" element={<AddCategory />} />
              <Route path="/ChangePassword" element={<ChangePassword />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/" element={<SignIn />} />
              <Route path="/Signup" element={<Signup />} />
            </Routes>
          </div>
        )}
      </>
    );
  };

  return (
    <HashRouter>
      {landingShow ? (
        <AppContent />
      ) : (
        <LandingScreen setLandingShow={setLandingShow} />
      )}
    </HashRouter>
  );
};

export default App;
