import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import db from "../db";
import "../assets/styles/style.css";
import { useSelector } from "react-redux";
import { selectCurrency } from "../components/Redux/Slices/currencySlice";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";

const ManageCategories = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [categoryExpenses, setCategoryExpenses] = useState([]);
  const selectedCurrency = useSelector(selectCurrency);
  const [openAccordionIds, setOpenAccordionIds] = useState([]);

  const [lastClickTime, setLastClickTime] = useState(null);
  const [lastClickedCategoryId, setLastClickedCategoryId] = useState(null);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteCategoryId(null);
    setCategoryExpenses([]);
  };
  const loadCategories = async () => {
    const categoriesFromDB = await db.categories.toArray();
    const sortedCategories = categoriesFromDB.sort((a, b) =>
      a.name?.localeCompare(b.name)
    );
    setCategories(
      sortedCategories?.filter((category) => category.isDeleted !== 1)
    );
  };
  const handleAccordionToggle = (categoryId) => {
    const now = Date.now();
    const threshold = 500;

    if (
      lastClickedCategoryId === categoryId &&
      now - lastClickTime < threshold
    ) {
      setOpenAccordionIds((prevIds) =>
        prevIds.includes(categoryId)
          ? prevIds.filter((id) => id !== categoryId)
          : [...prevIds, categoryId]
      );
    } else {
      setTimeout(() => {
        setOpenAccordionIds((prevIds) =>
          prevIds.includes(categoryId)
            ? prevIds.filter((id) => id !== categoryId)
            : [...prevIds, categoryId]
        );
      }, 500);

      setLastClickTime(now);
      setLastClickedCategoryId(categoryId);
    }
  };
  const renderAccordionItem = (category) => {
    const hasChildren = categories.some(
      (child) => child.parentId === category.id
    );
    const accordionBodyClassName = `accordion-body ${
      !hasChildren ? "accordion-body-padding" : ""
    }`;
    const isParentOpen = openAccordionIds.includes(category.id);

    return (
      <div
        key={category.id}
        className="accordion-item mb-4 p-2  shadow top-accordion "
      >
        <div className="d-flex justify-content-between align-items-center position-relative ">
          <div className="col-xl-11 col-md-10 col-sm-8">
            <h6
              className="accordion-header "
              onClick={() => handleAccordionToggle(category.id)}
            >
              <button
                className={`accordion-button category-div noSpace   bg-white p-1 fw-bold ${
                  isParentOpen ? "" : "collapsed"
                }`}
                style={{ cursor: hasChildren ? "pointer" : "auto" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${category.id}`}
                aria-expanded={isParentOpen}
              >
                <div className="noSpace ">
                  {" "}
                  {hasChildren ? (
                    <span className="arrow-icon    ">
                      {isParentOpen ? "▼" : "►"}
                    </span>
                  ) : (
                    <span style={{ visibility: "hidden" }}> ► </span>
                  )}{" "}
                  {category.name}{" "}
                </div>
              </button>
            </h6>
          </div>
          <div className=" edit-delete-btn d-flex ">
            <Link
              to={`/editcategory/${category.id}`}
              className="btn m-0 p-0 me-3 verticleAlign  edit-btn child-category-btn "
            >
              <span className="material-symbols-outlined">edit</span>
            </Link>
            <button
              className=" btn p-0 m-0 me-2  verticleAlign delete-btn  child-category-btn "
              disabled={hasChildren}
              onClick={() => openDeleteModal(category.id)}
              style={{ visibility: hasChildren ? "hidden" : "visible" }}
            >
              <span className="material-symbols-outlined">delete</span>
            </button>
          </div>
        </div>
        <div
          id={`collapse${category.id}`}
          className={`accordion-collapse collapse ${
            isParentOpen ? "show" : ""
          }`}
        >
          <div className={accordionBodyClassName}>
            {renderNestedAccordion(category.id)}
          </div>
        </div>
      </div>
    );
  };

  const renderNestedAccordion = (parentId) => {
    const nestedCategories = categories
      ?.filter((child) => child.isDeleted !== 1)
      ?.filter((child) => child.parentId === parentId);

    return nestedCategories.map((child) => {
      const hasNestedChildren = categories.some(
        (nestedChild) => nestedChild.parentId === child.id
      );

      const isChildOpen = openAccordionIds.includes(child.id);

      const accordionBodyClassName = `accordion-body ${
        !hasNestedChildren ? "accordion-body-padding" : ""
      }`;

      const handleChildAccordionToggle = (event) => {
        event.stopPropagation();
        handleAccordionToggle(child.id);
      };

      return (
        <div key={child.id} className="accordion-item  category-container ">
          <div className="d-flex justify-content-between align-items-center position-relative children-bg w-100">
            <div className="col-xl-11 col-md-10 col-sm-8">
              <h6 className="accordion-header">
                <button
                  className={`accordion-button accordion-nested  category-div p-3  ${
                    isChildOpen ? "" : "collapsed"
                  }`}
                  style={{ cursor: hasNestedChildren ? "pointer" : "auto" }}
                  type="button"
                  onClick={handleChildAccordionToggle}
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${child.id}`}
                  aria-expanded={isChildOpen}
                >
                  <div className="d-flex justify-content-between accordion-child  ">
                    <div className="d-flex">
                      {hasNestedChildren ? (
                        <span className="arrow-icon   ">
                          {isChildOpen ? "▼" : "►"}
                        </span>
                      ) : (
                        <span style={{ visibility: "hidden" }}>►</span>
                      )}{" "}
                      <span
                        className={`ms-1   ${
                          hasNestedChildren ? "fw-bold" : ""
                        }`}
                      >
                        {child.name}
                      </span>
                    </div>
                  </div>
                </button>
              </h6>
            </div>

            <div className=" d-flex   ">
              <Link
                className="btn m-0 p-0 me-3 verticleAlign  edit-btn child-category-btn"
                to={`/editcategory/${child.id}`}
              >
                <span className="material-symbols-outlined">edit</span>
              </Link>
              <button
                className=" btn m-0 p-0 me-2 verticleAlign  delete-btn child-category-btn "
                onClick={() => openDeleteModal(child.id)}
                disabled={hasNestedChildren}
                style={{ visibility: hasNestedChildren ? "hidden" : "visible" }}
              >
                <span className="material-symbols-outlined">delete</span>
              </button>
            </div>
          </div>

          <div
            id={`collapse${child.id}`}
            className={`accordion-collapse collapse ${
              isChildOpen ? "show" : ""
            }`}
          >
            <div className={accordionBodyClassName}>
              {renderNestedAccordion(child.id)}
            </div>
          </div>
        </div>
      );
    });
  };
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  const openDeleteModal = async (categoryId) => {
    setShowDeleteModal(true);
    setDeleteCategoryId(categoryId);
    const allExpenses = await fetchCategoryExpensesRecursively(categoryId);
    setCategoryExpenses(allExpenses);
  };
  const handleDeleteCategoryWithExpenses = async (categoryId) => {
    try {
      const childCategories = categories.filter(
        (child) => child.parentId === categoryId
      );

      await Promise.all(
        childCategories.map(async (child) => {
          await handleDeleteCategoryWithExpenses(child.id);
        })
      );

      const expensesToDelete = await db.expenses
        .where("category")
        .equals(categoryId)
        .toArray();

      await Promise.all(
        expensesToDelete.map((expense) =>
          db.expenses.update(expense.id, { isDeleted: 1 })
        )
      );

      await db.categories.update(categoryId, { isDeleted: 1 });

      loadCategories();
      closeDeleteModal();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };
  const handleDeleteCategoryOnly = async (categoryId) => {
    try {
      const childCategories = await fetchChildCategories(categoryId);

      await Promise.all([
        db.categories.update(parseInt(categoryId), { isDeleted: 1 }),
        ...childCategories.map((child) => db.categories.delete(child.id)),
        db.expenses
          .where("category")
          .equals(categoryId)
          .modify({ isEdited: 1, category: null }),
      ]);

      loadCategories();
      closeDeleteModal();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };
  const fetchChildCategories = async (parentId) => {
    const children = categories.filter((child) => child.parentId === parentId);
    let allChildren = [...children];
    for (const child of children) {
      const nestedChildren = await fetchChildCategories(child.id);
      allChildren = [...allChildren, ...nestedChildren];
    }
    return allChildren;
  };

  const fetchCategoryExpensesRecursively = useCallback(
    async (categoryId) => {
      try {
        const childCategories = categories.filter(
          (child) => child.parentId === categoryId
        );

        const childExpensesPromises = childCategories.map(async (child) => {
          const childExpenses = await fetchCategoryExpensesRecursively(
            child.id
          );
          return childExpenses;
        });

        const expensesFromDB = await db.expenses
          .where("category")
          .equals(categoryId)
          .toArray();

        const expensesWithCategoryName = expensesFromDB.map((expense) => ({
          ...expense,
          categoryName: categories.find((cat) => cat.id === categoryId)?.name,
        }));

        const childExpenses = await Promise.all(childExpensesPromises);

        return [...expensesWithCategoryName, ...childExpenses.flat()];
      } catch (error) {
        console.error("Error fetching expenses:", error);
        return [];
      }
    },
    [categories]
  );

  return (
    <div className="container main-div">
      <button
        className="  back-btn  "
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBackCircleOutline />
      </button>
      <h4 className="heading-txt pt-2 pb-3">Manage Categories</h4>

      <div className="card  main-category-div">
        <div className=" text-center pb-4">
          <button
            className="save-btn rounded-pill "
            onClick={() => {
              navigate("/AddCategory");
            }}
          >
            <span className="material-symbols-outlined verticleAlign">
              docs_add_on
            </span>{" "}
            <span>Add Category</span>
          </button>
        </div>
        <div className="accordion" id="categoryAccordion">
          {categories
            ?.filter((category) => category.isDeleted !== 1)
            ?.filter((category) => !category.parentId)
            .map((category) => renderAccordionItem(category))}
        </div>
      </div>

      <div
        className={`modal fade ${showDeleteModal ? "show" : ""}`}
        style={{ display: showDeleteModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteModal"
        aria-hidden={!showDeleteModal}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModal">
                Confirm
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeDeleteModal}
              />
            </div>
            <div className="modal-body">
              {categoryExpenses.length > 0 && (
                <div>
                  <h6>
                    {" "}
                    The following expenses are linked with this category:
                  </h6>
                  <div className=" card p-2 delete-table">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Item</th>
                          <th>Category</th>
                          <th>Quantity</th>
                          <th className="text-end">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryExpenses
                          .filter((expense) => expense.isDeleted !== 1)
                          .map((expense) => (
                            <tr key={expense.id}>
                              <td className="noSpace">
                                {formatDate(expense.date)}
                              </td>
                              <td className="noSpace">{expense.item}</td>
                              <td className="noSpace">
                                {expense.categoryName}
                              </td>
                              <td>{expense.quantity}</td>
                              <td className="noSpace  text-end currencyIcons">
                                <NumericFormat
                                  value={parseFloat(expense.amount).toFixed(2)}
                                  displayType={"text"}
                                  thousandsGroupStyle="lakh"
                                  thousandSeparator=","
                                  prefix={selectedCurrency.label}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <br />
                  <h6>Would you like to remove these expenses as well?</h6>
                  <br />
                  <h6>
                    Click '<span className="fw-bold"> Yes</span>' to delete the
                    category as well as its expense entries. Click '
                    <span className="fw-bold"> No</span>' to delete only the
                    category.
                  </h6>
                </div>
              )}
            </div>
            {categoryExpenses.length > 0 ? (
              <div className=" display-between p-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeDeleteModal}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-warning me-3"
                    onClick={() => handleDeleteCategoryOnly(deleteCategoryId)}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() =>
                      handleDeleteCategoryWithExpenses(deleteCategoryId)
                    }
                  >
                    Yes
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h6 className="p-2">
                  {" "}
                  Are you sure you want to delete this{" "}
                  <span className="fw-bold">
                    {" "}
                    {
                      categories.find(
                        (category) => category.id === deleteCategoryId
                      )?.name
                    }
                  </span>
                  ?
                </h6>
                <div className="d-flex border  justify-content-end align-items-center p-3">
                  <div>
                    <button
                      className="btn btn-secondary  me-3"
                      onClick={closeDeleteModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDeleteCategoryOnly(deleteCategoryId)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManageCategories;
