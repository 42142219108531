import React, { useState, useEffect } from "react";
import { db } from "../db";
import { submitInitialRecords, SyncRecords } from "../API";
import { ImportIndexedb, ExportIndexedb } from "../components/IndexedDBManager";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function Admin() {
  const [messageType, setMessageType] = useState("");
  const accessToken = localStorage.getItem("accessToken");
  const [PushDisabled, setPushDisabled] = useState(false);
  const [SyncDisabled, setSyncDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [pushloading, setPushLoading] = useState(false);
  const [lastSyncTime, setLastSyncTime] = useState(
    localStorage.getItem("lastSyncTime")
  );
  const user_id = localStorage.getItem("user_id");

  const navigate = useNavigate();
  useEffect(() => {
    db.expenses
      .count()
      .then((count) => {})
      .catch((error) => {
        console.error("Error fetching total expenses count:", error);
      });
  }, []);

  // ------------------------------------

  function formatTimeElapsed(lastSyncTime) {
    const now = new Date();
    const syncTime = new Date(lastSyncTime);
    const timeDiff = now.getTime() - syncTime.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours >= 1) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes >= 1) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  }
  const updateLastSyncTime = () => {
    const now = new Date();
    const formattedTime = now.toLocaleString();
    setLastSyncTime(formattedTime);
    localStorage.setItem("lastSyncTime", formattedTime);
  };

  function handleSync() {
    setLoading(true);
    setSyncDisabled(true);

    setTimeout(() => {
      setSyncDisabled(false);
    }, 60000);
    SyncRecords(user_id, accessToken)
      .then(() => {
        setLoading(false);
        updateLastSyncTime();
      })
      .catch((error) => {
        console.error("Error syncing data:", error);
        setLoading(false);
      });
    setMessage("Successfully updated");
    setMessageType("success");
  }

  function Importdata() {
    setPushLoading(true);
    setPushDisabled(true);

    submitInitialRecords(user_id, accessToken)
      .then(() => {
        setPushLoading(false);
        updateLastSyncTime();
      })
      .catch((error) => {
        console.error("Error syncing data:", error);
        setPushLoading(false);
      });
    setTimeout(() => {
      setPushDisabled(false);
    }, 60000);
    setMessage("Successfully Submitted");
    setMessageType("success");
  }
  const premiumStatus = localStorage.getItem("isPremium");

  useEffect(() => {
    if (premiumStatus === "false") {
      navigate("/Home");
    }
  });

  return (
    <div className="container main-div">
      <button
        className="  back-btn  "
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBackCircleOutline />
      </button>
      <h4 className="heading-txt pb-4">Admin </h4>

      <div className="child-div card  setting-div">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-2 ">
            <div className="mb-3  ">
              <label className="form-label">Import Data</label>
              <br />{" "}
              <input
                className="btn text-muted p-0 m-0"
                type="file"
                accept="application/json"
                onChange={(event) => ImportIndexedb(event)}
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-2 ">
            <div className="mb-3">
              <label className="form-label">Export Data</label>
              <br />
              <button
                className="loginSetting-btn  rounded-pill "
                onClick={ExportIndexedb}
              >
                Export Database
              </button>
            </div>
          </div>
          {accessToken && (
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-2 ">
                <div className="mb-3">
                  <label className="form-label">Push data to cloud</label>{" "}
                  <br />
                  <button
                    className={`border-0 text-start bg-transparent mt-2  setting-div ${
                      SyncDisabled ? "disabled-class" : ""
                    }`}
                    onClick={Importdata}
                    disabled={SyncDisabled}
                  >
                    {pushloading ? (
                      <div className="display-center">
                        <div
                          className="spinner-border text-success"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <div className="display-center">
                        <div className="">
                          <button
                            className={` rounded-pill noSpace ${
                              PushDisabled
                                ? "btn btn-secondary "
                                : "loginSetting-btn "
                            }`}
                          >
                            <span className="me-1 fw-bold verticleAlign">
                              Upload
                            </span>{" "}
                            <span className="material-symbols-outlined verticleAlign">
                              Upload
                            </span>
                          </button>{" "}
                          <div className="mt-3 display-center text-muted noSpace">
                            {PushDisabled && (
                              <span>
                                Next upload will be available in 1 minute
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-2 ">
                <div className="mb-3">
                  <label className="form-label">Sync Expenses</label>
                  <br />
                  <button
                    className={`border-0 text-start bg-transparent mt-2  setting-div ${
                      SyncDisabled ? "disabled-class" : ""
                    }`}
                    onClick={handleSync}
                    disabled={SyncDisabled}
                  >
                    {loading ? (
                      <div className="display-center">
                        <div
                          className="spinner-border text-success"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <div className="display-center">
                        <div className="">
                          <button
                            className={` rounded-pill noSpace ${
                              SyncDisabled
                                ? "btn btn-secondary "
                                : "loginSetting-btn "
                            }`}
                          >
                            <span className="me-1 fw-bold verticleAlign">
                              Sync
                            </span>{" "}
                            <span className="material-symbols-outlined verticleAlign">
                              sync
                            </span>
                          </button>{" "}
                          <div className="mt-3 display-center text-muted noSpace">
                            {SyncDisabled ? (
                              <span>
                                Next sync will be available in 1 minute
                              </span>
                            ) : (
                              <span className="me-1">
                                Last Sync :
                                {lastSyncTime
                                  ? formatTimeElapsed(lastSyncTime)
                                  : "Never"}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className=" noSpace SuccessMessage-div ">
          {message && (
            <div
              className={` successMessage ${
                messageType === "success" ? "alert-success" : "alert-danger"
              }`}
            >
              <span className="material-symbols-outlined successMessage">
                task_alt
              </span>
              <span className="successMessage;"> {message} </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Admin;
