import React from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/style.css";

function FourZeroFour() {
  const navigate = useNavigate();

  return (
    <div className=" container-fluid  FourZeroFour-container ">
      {" "}
      <div className="row  login-container">
        <div className="col-lg-6 col-md-6 col-sm-12   border-0 display-center       ">
          <div className="FourZeroFour-img ">
            <img src="assets/404.svg" alt="404" className="login-img " />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12  display-center ">
          <div className="shadow   text-center text-color FourZeroFour-card ">
            <div className="noSpace">
              <span className="fs-1 text-orange "> OH NO !</span>{" "}
              <span className="text-color ms-1">but that's Okay</span>
            </div>
            <br />
            <br />
            <p>
              Even cool people lose their way online at same point, and today is
              your point. Don't worry, you'll find your way back.
            </p>
            <br />
            <br />
            <button
              className="save-btn rounded-pill"
              onClick={() => navigate("/Home")}
            >
              Back to Home
            </button>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default FourZeroFour;
