import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import formInputsJson from "../components/formInputs.json";
import { db } from "../db";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { selectCurrency } from "../components/Redux/Slices/currencySlice";
import CategoryLists from "../components/CategoryLists";
import { Button } from "react-bootstrap";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { NumericFormat } from "react-number-format";

const AddExpenses = () => {
  const selectedCurrency = useSelector(selectCurrency);
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [userAddedCategories, setUserAddedCategories] = useState([]);
  const formInputs = formInputsJson.formInputs;
  const [formData, setFormData] = useState({
    item: "",
    quantity: "1",
    category: null,
    amount: "",
    currency: selectedCurrency.code,
    date: new Date().toISOString().split("T")[0],
  });
  const [editingExpense, setEditingExpense] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e, addAnother = false) => {
    e.preventDefault();
    if (formRef.current.checkValidity()) {
      try {
        const dateTime = new Date(`${formData.date} `);
        const formattedDateTime = format(dateTime, "MMMM dd, yyyy  ");
        const updatedExpense = {
          ...formData,
          dateTime: formattedDateTime,
          category: parseInt(formData.category),
          isEdited: 1,
        };

        if (editingExpense) {
          await db.expenses.update(editingExpense, updatedExpense);
          setEditingExpense(null);
        } else {
          await db.expenses.add(updatedExpense);
        }

        setFormData({
          item: "",
          quantity: "1",
          category: null,
          amount: "",
          currency: selectedCurrency.code,
          date: new Date().toISOString().split("T")[0],
        });

        if (!addAnother) {
          setMessage("Successfully Updated");
          setMessageType("success");
          setTimeout(() => navigate("/Home"), 2000);
        } else {
          setMessage("Successfully Added");
          setMessageType("success");
        }
        if (addAnother) {
          setFormData({
            item: "",
            quantity: "1",
            category: null,
            amount: "",
            currency: selectedCurrency.code,
            date: new Date().toISOString().split("T")[0],
          });
          setMessage("Successfully Added");
          setMessageType("success");
        }
      } catch (error) {
        console.error("Error adding/updating expense:", error);
        setMessage("Error adding/updating expense");
        setMessageType("error");
      }
    } else {
      formRef.current.reportValidity();
    }
    // setTimeout(() => {
    //   setMessage(null);
    //   setMessageType(null);
    // }, 3000);
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      category: value,
    }));
  };

  useEffect(() => {
    const fetchUserAddedCategories = async () => {
      try {
        const categoriesFromDB = await db.categories.toArray();
        setUserAddedCategories(categoriesFromDB);
      } catch (error) {
        console.error("Error fetching user-added categories:", error);
      }
    };

    fetchUserAddedCategories();
  }, []);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      currency: selectedCurrency.code,
    }));
  }, [selectedCurrency]);

  return (
    <>
      <div className="container p-2   main-div ">
        <button
          className="  back-btn  "
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoChevronBackCircleOutline />
        </button>
        <h4 className="heading-txt ">Add Expense</h4>

        <div className=" card child-div  ">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="row">
              {formInputs.map((input, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-2">
                  {input.type === "select" ? (
                    <div>
                      <label  className="form-label">
                        {input.label}
                      </label>
                      <CategoryLists
                        className="form-control"
                        categories={userAddedCategories?.filter(
                          (category) => category.isDeleted !== 1
                        )}
                        selectedParent={formData.category}
                        onChange={handleCategoryChange}
                      />
                    </div>
                  ) : (
                    <div>
                      <label  className="form-label">
                        {input.label}
                      </label>
                      {input.name === "amount" ? (
                        <NumericFormat
                          value={formData.amount}
                          onValueChange={(values) => {
                            const { value } = values;
                            setFormData((prevData) => ({
                              ...prevData,
                              amount: value,
                            }));
                          }}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                          prefix={selectedCurrency.label}
                          placeholder={input.placeholder}
                          className="form-control currencyIcons"
                          required={
                            input.name === "item" || input.name === "amount"
                          }
                        />
                      ) : (
                        <input
                          type={input.type}
                          id={input.id}
                          name={input.name}
                          value={formData[input.name]}
                          onChange={
                            input.name === "category"
                              ? handleCategoryChange
                              : handleChange
                          }
                          className="form-control"
                          placeholder={input.placeholder}
                          required={
                            input.name === "item" || input.name === "amount"
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className=" submitExpense-btn ">
              <Button
                className="me-3 noSpacebtn btn-warning  rounded-pill noSpace"
                onClick={(e) => handleSubmit(e, true)}
              >
                <span className="material-symbols-outlined verticleAlign me-1">
                  more_time
                </span>
                <span className=" verticleAlign">Add Another</span>
              </Button>
              <Button className="save-btn rounded-pill noSpace" type="submit">
                <span className="material-symbols-outlined verticleAlign me-1">
                  save
                </span>
                <span className="verticleAlign ">save</span>
              </Button>
            </div>
          </form>

          <div className="noSpace SuccessMessage-div">
            {message && (
              <div
                className={` successMessage ${
                  messageType === "success" ? "alert-success" : "alert-danger"
                }`}
              >
                <span className="material-symbols-outlined successMessage">
                  task_alt
                </span>
                <span className="successMessage;"> {message} </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddExpenses;
