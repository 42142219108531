import React, { useState, useEffect, useCallback } from "react";
import { db } from "../db";
import { useNavigate } from "react-router-dom";
import "../assets/styles/style.css";
import { useSelector } from "react-redux";
import { selectCurrency } from "../components/Redux/Slices/currencySlice";
import FilterButtons from "../components/FilterButtons";
import TotalAmount from "../components/TotalAmount ";
import { NumericFormat } from "react-number-format";

export default function Home() {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const navigate = useNavigate();
  const [totalAmountAll, setTotalAmountAll] = useState(0);
  const [totalAmountToday, setTotalAmountToday] = useState(0);
  const [totalAmountWeek, setTotalAmountWeek] = useState(0);
  const [totalAmountMonth, setTotalAmountMonth] = useState(0);
  const [categoryExpenses, setCategoryExpenses] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    calculateTotalAmounts(filter);
  };
  const selectedCurrency = useSelector(selectCurrency);

  const fetchCategoryExpenses = useCallback(async () => {
    try {
      const expensesFromDB = await db.expenses.toArray();
      const categorizedExpenses = {};

      expensesFromDB.forEach((expense) => {
        if (!categorizedExpenses[expense.category]) {
          categorizedExpenses[expense.category] = [];
        }
        categorizedExpenses[expense.category].push(expense);
      });

      setCategoryExpenses(categorizedExpenses);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  }, []);
  const calculateTotalAmount = (expenses) => {
    return expenses
      ?.filter((expense) => expense.isDeleted !== 1)
      .reduce((sum, expense) => sum + parseFloat(expense.amount), 0);
  };
  const filterExpensesByDate = useCallback(
    (expense) => {
      const currentDate = new Date();
      const expenseDate = new Date(expense.date);

      switch (selectedFilter) {
        case "today":
          const todayStart = new Date();
          todayStart.setHours(0, 0, 0, 0);
          const todayEnd = new Date();
          todayEnd.setHours(23, 59, 59, 999);
          return expenseDate >= todayStart && expenseDate <= todayEnd;

        case "currentWeek":
          const currentWeekStart = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() - currentDate.getDay()
          );
          const currentWeekEnd = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + (6 - currentDate.getDay()),
            23,
            59,
            59,
            999
          );
          return (
            expenseDate >= currentWeekStart && expenseDate <= currentWeekEnd
          );

        case "currentMonth":
          const currentMonthStart = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
          );
          const currentMonthEnd = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0,
            23,
            59,
            59,
            999
          );
          return (
            expenseDate >= currentMonthStart && expenseDate <= currentMonthEnd
          );

        case "year":
          return (
            expenseDate >
            new Date(currentDate.setFullYear(currentDate.getFullYear() - 1))
          );

        default:
          return true;
      }
    },
    [selectedFilter]
  );

  const calculateTotalAmounts = useCallback(
    (filter, expenses) => {
      const filteredExpenses = expenses?.filter(filterExpensesByDate);
      const totalAmount = filteredExpenses
        ?.filter((expense) => expense.isDeleted !== 1)
        ?.reduce((sum, expense) => sum + parseFloat(expense.amount), 0);

      switch (filter) {
        case "all":
          setTotalAmountAll(totalAmount);
          break;
        case "today":
          setTotalAmountToday(totalAmount);
          break;
        case "currentWeek":
          setTotalAmountWeek(totalAmount);
          break;
        case "currentMonth":
          setTotalAmountMonth(totalAmount);
          break;
        default:
          break;
      }
    },
    [
      setTotalAmountAll,
      setTotalAmountToday,
      setTotalAmountWeek,
      setTotalAmountMonth,
      filterExpensesByDate,
    ]
  );

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const expensesFromDB = await db.expenses.toArray();
        calculateTotalAmounts(selectedFilter, expensesFromDB);
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    };

    fetchExpenses();
    fetchCategoryExpenses();
  }, [fetchCategoryExpenses, selectedFilter, calculateTotalAmounts]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesFromDB = await db.categories.toArray();
        setCategoryData(
          categoriesFromDB?.filter((category) => category.isDeleted !== 1)
        );
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
    // syncDataIfOnline();
  }, []);

  return (
    <div className="container position-relative   main-div">
      <h4 className="mb-4 heading-txt  ">Expenses</h4>

      <div className="card child-div    ">
        <div className="heading-total-amount mb-3">
          <div className="me-2 ">
            <span className="noSpace me-2">Total Expenses</span>
            <span className="currencyIcons">
              <TotalAmount
                selectedFilter={selectedFilter}
                totalAmountAll={totalAmountAll}
                totalAmountToday={totalAmountToday}
                totalAmountWeek={totalAmountWeek}
                totalAmountMonth={totalAmountMonth}
                selectedCurrency={selectedCurrency}
              />
            </span>
          </div>
          <div>
            <img src="assets/Green-money.svg" alt="money-icon" />
          </div>
        </div>
        <div className="d-flex justify-content-end  ">
          <span>
            <FilterButtons
              selectedFilter={selectedFilter}
              handleFilterChange={handleFilterChange}
            />
          </span>
        </div>
        <div className="pt-4">
          {Object.keys(categoryExpenses)
            .filter((category) => {
              const categoryExpensesFiltered =
                categoryExpenses[category].filter(filterExpensesByDate);
              return (
                categoryExpensesFiltered.length > 0 &&
                categoryExpensesFiltered.some(
                  (expense) => expense.isDeleted !== 1
                )
              );
            })
            .reverse()
            .map((category) => (
              <div className="row mb-4 " key={category}>
                <button
                  className="btn table-home  text-size "
                  onClick={() =>
                    navigate(`/details/${category}/${selectedFilter}`)
                  }
                >
                  <div className="category-container">
                    <div className="category-row">
                      <div className="category-cell text-start">
                        {
                          categoryData.find((c) => c.id === parseInt(category))
                            ?.name
                        }
                      </div>
                      <div className="category-cell noSpace ms-3 me-3 currencyIcons">
                        <NumericFormat
                          value={calculateTotalAmount(
                            categoryExpenses[category].filter(
                              filterExpensesByDate
                            )
                          ).toFixed(2)}
                          thousandsGroupStyle="lakh"
                          thousandSeparator=","
                          displayType="text"
                          prefix={selectedCurrency.label}
                        />
                      </div>
                      <div className="category-cell  homeZome-btn material-symbols-outlined text-orange">
                        zoom_out_map
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
