import React from "react";

const FilterButtons = ({ selectedFilter, handleFilterChange }) => {
  return (
    <div className="d-flex  ">
      <div className="col-auto   me-1  ">
        <button
          className={`btn-filter ${
            selectedFilter === "all" ? "btn-filter-active" : ""
          }`}
          onClick={() => handleFilterChange("all")}
        >
          All
        </button>
      </div>
      <div className="col-auto  me-1 ">
        {" "}
        <button
          className={`   btn-filter    ${
            selectedFilter === "today " ? "btn-filter-active" : ""
          }`}
          onClick={() => handleFilterChange("today")}
        >
          Today
        </button>
      </div>
      <div className="col-auto  me-1 ">
        {" "}
        <button
          className={`   btn-filter  ${
            selectedFilter === "currentWeek" ? "btn-filter-active" : ""
          }`}
          onClick={() => handleFilterChange("currentWeek")}
        >
          Week
        </button>
      </div>
      <div className="col-auto   ">
        {" "}
        <button
          className={`   btn-filter   ${
            selectedFilter === "currentMonth" ? "btn-filter-active" : ""
          }`}
          onClick={() => handleFilterChange("currentMonth")}
        >
          Month
        </button>
      </div>
    </div>
  );
};

export default FilterButtons;
