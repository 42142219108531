import { db } from "../db";

export const ExportIndexedb = async () => {
  const expenses = await db.expenses.toArray();
  const categories = await db.categories.toArray();
  const data = {
    expenses,
    categories,
  };

  const blob = new Blob([JSON.stringify(data)], {
    type: "application/json",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "ExpenseApp-Data.json";
  a.click();
  URL.revokeObjectURL(url);
};

export const ImportIndexedb = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = async (e) => {
    const data = JSON.parse(e.target.result);

    await db.transaction("rw", db.expenses, db.categories, async () => {
      await db.expenses.clear();
      await db.categories.clear();

      await db.expenses.bulkAdd(data.expenses);
      await db.categories.bulkAdd(data.categories);
    });

    alert("Database imported successfully!");
  };
  reader.readAsText(file);
};
