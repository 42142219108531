import React from "react";
import { NumericFormat } from "react-number-format";

const TotalAmount = ({
  selectedFilter,
  totalAmountAll,
  totalAmountToday,
  totalAmountWeek,
  totalAmountMonth,
  selectedCurrency,
}) => {
  return (
    <div className="noSpace">
      {selectedFilter === "all" && (
        <span>
          <NumericFormat
            value={totalAmountAll?.toFixed(2)}
            displayType={"text"}
            thousandsGroupStyle="lakh"
            thousandSeparator=","
            prefix={selectedCurrency.label}
          />
        </span>
      )}
      {selectedFilter === "today" && (
        <span>
          <NumericFormat
            value={totalAmountToday?.toFixed(2)}
            displayType={"text"}
            thousandsGroupStyle="lakh"
            thousandSeparator=","
            prefix={selectedCurrency.label}
          />
        </span>
      )}
      {selectedFilter === "currentWeek" && (
        <span>
          {/* Total Expenses (Week):{" "} */}
          <NumericFormat
            value={totalAmountWeek?.toFixed(2)}
            displayType={"text"}
            thousandsGroupStyle="lakh"
            thousandSeparator=","
            prefix={selectedCurrency.label}
          />
        </span>
      )}
      {selectedFilter === "currentMonth" && (
        <span>
          <NumericFormat
            value={totalAmountMonth?.toFixed(2)}
            displayType={"text"}
            thousandsGroupStyle="lakh"
            thousandSeparator=","
            prefix={selectedCurrency.label}
          />
        </span>
      )}
    </div>
  );
};

export default TotalAmount;
