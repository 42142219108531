import React from "react";
import { Link, useNavigate } from "react-router-dom";

function LandingScreen({ setLandingShow }) {
  const navigate = useNavigate();

  const onStandardPlan = () => {
    localStorage.setItem("landingShow", "true");

    navigate("/Home");
    window.location.reload();
  };

  const handleFAQ = () => {
    localStorage.setItem("landingShow", "true");
    localStorage.setItem("hasVisited", true);
    navigate("/FAQ");
    window.location.reload();
  };

  const handleSignUpClick = () => {
    setLandingShow(true);
    localStorage.setItem("landingShow", "true");
    navigate("/");
  };

  return (
    <div className="container-fluid bg-light">
      <div className="container display-center card border-0  ">
        <div className=" ">
          <div className="row gx-3 gy-3 p-2 pb-4 ">
            <div className="col-lg-6 col-md-6 col-sm-12      ">
              <div
                className=" li-4 landingCard pb-4 pt-4 "
                style={{
                  backgroundColor: "#f5e02880",
                  borderBottomLeftRadius: "80px",
                  borderBottomRightRadius: "80px",
                }}
              >
                <div className=" text-center ">
                  <img src="assets/App.png " alt="Login-img" height={100} />{" "}
                </div>
                <div className="text-center pt-4 fw-bolder fs-3 ">
                  {"  "}
                  <p>Paid-It</p>
                  <p className="  pb-3">Manage your daily expenses</p>{" "}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12  display-center card  p-2 pt-4 border-0  display-center">
              <span className="paragraph ">
                <span
                  className="fs-3 fw-bolder"
                  style={{ textDecoration: "underline" }}
                >
                  Highlights
                </span>
                <ul>
                  <li>Records Expenses</li>
                  <li> Categories Expenses</li>
                  <li>Views totals by category.</li>
                  <li> Search expenses and view a reports</li>
                  <li> Switch currency.</li>
                  <li> Multi device access.</li>
                </ul>
              </span>
            </div>
          </div>
          {/* Screenshot card */}

          <div className="p-2 pt-4 pb-5  screenshot-container ">
            <div className="row gx-3 gy-3 p-2">
              <div className="col-lg-6 col-md-6 col-sm-12 order-2 order-md-1 display-center">
                <img
                  src="assets/images/Home2.png"
                  className="screenshotImg"
                  alt="Signup"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 order-1 order-md-2 display-center">
                <p className="ms-3">
                  Filters and displays expenses by time period, with categorized
                  totals.
                </p>
              </div>
            </div>
            <div className="row gx-3 gy-3 p-2 ">
              <div className="col-lg-6 col-md-6 col-sm-12  display-center  ">
                <p>
                  {" "}
                  Registration/Login with email, password, Google login, and
                  skip options (allowing users to continue without logging in).
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12  display-center ">
                <img
                  src="assets/images/signup2.png"
                  className="screenshotImg ms-3"
                  alt="Home"
                />
              </div>
            </div>{" "}
            <div className="row gx-3 gy-3 p-2 ">
              <div className="col-lg-6 col-md-6 col-sm-12 order-2 order-md-1  display-center  ">
                <img
                  src="assets/images/category2.png"
                  className="screenshotImg me-3"
                  alt="Signup"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 order-1 order-md-2  display-center   ">
                <p>
                  {" "}
                  Manage categories and subcategories, with add/edit/delete
                  options and accordion view.
                </p>
              </div>
            </div>{" "}
            <div className="row gx-3 gy-3 p-2 ">
              <div className="col-lg-6 col-md-6 col-sm-12  display-center ">
                <p>
                  {" "}
                  Supports multiple currencies, items per page, password reset,
                  and data sync.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12  display-center   ">
                <img
                  src="assets/images/settings2.png"
                  className="screenshotImg ms-3"
                  alt="Home"
                />
              </div>
            </div>{" "}
          </div>
          {/* Plan details Card */}
          <div className="display-center position-relative  planCard   p-2 ">
            <div>
              <img
                src="assets/images/color-full-circle.svg"
                className="circleImgTop  "
                alt="circle"
              />
              <img
                src="assets/images/color-full-circle.svg"
                className="circleImgbottom"
                alt="circle"
              />
              <p className="fs-3 fw-bolder text-center  p-2 ">Plan Details </p>

              <div
                className="row gx-2 gy-2 position-relative"
                style={{ zIndex: 10 }}
              >
                <div className="col-sm-12 col-md-6 d-flex">
                  <div className="nonPremiumCard p-2 d-flex flex-column w-100">
                    <h5 className="p-2 fw-bold">Standard Plan</h5>
                    <ul>
                      <li>Local storage on a single device.</li>
                      <li>Does not support multi-device.</li>
                      <li>Basic data security.</li>
                      <li>Data accessible offline-only access.</li>
                      <li>Data can be Import/Export.</li>
                    </ul>
                    <div className="text-center p-4 mt-auto">
                      <button
                        className="btn-warning btn"
                        onClick={onStandardPlan}
                      >
                        Continue with Standard plan
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 d-flex">
                  <div className="premiumCard p-2 d-flex flex-column w-100">
                    <h5 className="p-2 fw-bold">Premium Plan</h5>
                    <ul>
                      <li>Cloud storage for access across devices.</li>
                      <li>Multi-device Support.</li>
                      <li>Enhanced data security.</li>
                      <li>Data accessible online and offline.</li>
                      <li>Data can be Import/Export.</li>
                    </ul>
                    <div className="text-center p-4 mt-auto">
                      <button
                        className="btn-warning btn"
                        onClick={handleSignUpClick}
                      >
                        Sign Up for Premium plan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*   testimonialCard     */}

          <div className="display-center pt-4 pb-5 p-2 testimonialCard ">
            <div>
              <p className="text-center fs-3 fw-bolder">Testimonials</p>
              <div className="container py-3">
                <div id="testimonialCarousel" className="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="card-sm rounded-3">
                        <div className="quotes display-2 text-body-tertiary">
                          <i className="bi bi-quote"></i>
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                            "Some quick example text to build on the card title
                            and make up the bulk of the card's content."
                          </p>
                          <div className="d-flex align-items-center pt-2">
                            <img
                              src="https://codingyaar.com/wp-content/uploads/square-headshot-1.png"
                              alt="bootstrap testimonial carousel slider 2"
                            />
                            <div>
                              <h5 className="card-title fw-bold">Jane Doe</h5>
                              <span className="text-secondary">
                                CEO, Example Company
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card-sm rounded-3">
                        <div className="quotes display-2 text-body-tertiary">
                          <i className="bi bi-quote"></i>
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                            "Some quick example text to build on the card title
                            and make up the bulk of the card's content."
                          </p>
                          <div className="d-flex align-items-center pt-2">
                            <img
                              src="https://codingyaar.com/wp-content/uploads/square-headshot-2.png"
                              alt="bootstrap testimonial carousel slider 2"
                            />
                            <div>
                              <h5 className="card-title fw-bold">June Doe</h5>
                              <span className="text-secondary">
                                CEO, Example Company
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card-sm rounded-3">
                        <div className="quotes display-2 text-body-tertiary">
                          <i className="bi bi-quote"></i>
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                            "Some quick example text to build on the card title
                            and make up the bulk of the card's content."
                          </p>
                          <div className="d-flex align-items-center pt-2">
                            <img
                              src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                              alt="bootstrap testimonial carousel slider 2"
                            />
                            <div>
                              <h5 className="card-title fw-bold">John Doe</h5>
                              <span className="text-secondary">
                                CEO, Example Company
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card-sm rounded-3">
                        <div className="quotes display-2 text-body-tertiary">
                          <i className="bi bi-quote"></i>
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                            "Some quick example text to build on the card title
                            and make up the bulk of the card's content."
                          </p>
                          <div className="d-flex align-items-center pt-2">
                            <img
                              src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                              alt="bootstrap testimonial carousel slider 2"
                            />
                            <div>
                              <h5 className="card-title fw-bold">John Doe</h5>
                              <span className="text-secondary">
                                CEO, Example Company
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card-sm rounded-3">
                        <div className="quotes display-2 text-body-tertiary">
                          <i className="bi bi-quote"></i>
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                            "Some quick example text to build on the card title
                            and make up the bulk of the card's content."
                          </p>
                          <div className="d-flex align-items-center pt-2">
                            <img
                              src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                              alt="bootstrap testimonial carousel slider 2"
                            />
                            <div>
                              <h5 className="card-title fw-bold">John Doe</h5>
                              <span className="text-secondary">
                                CEO, Example Company
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card-sm rounded-3">
                        <div className="quotes display-2 text-body-tertiary">
                          <i className="bi bi-quote"></i>
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                            "Some quick example text to build on the card title
                            and make up the bulk of the card's content."
                          </p>
                          <div className="d-flex align-items-center pt-2">
                            <img
                              src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                              alt="bootstrap testimonial carousel slider 2"
                            />
                            <div>
                              <h5 className="card-title fw-bold">John Doe</h5>
                              <span className="text-secondary">
                                CEO, Example Company
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card-sm rounded-3">
                        <div className="quotes display-2 text-body-tertiary">
                          <i className="bi bi-quote"></i>
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                            "Some quick example text to build on the card title
                            and make up the bulk of the card's content."
                          </p>
                          <div className="d-flex align-items-center pt-2">
                            <img
                              src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                              alt="bootstrap testimonial carousel slider 2"
                            />
                            <div>
                              <h5 className="card-title fw-bold">John Doe</h5>
                              <span className="text-secondary">
                                CEO, Example Company
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card-sm rounded-3">
                        <div className="quotes display-2 text-body-tertiary">
                          <i className="bi bi-quote"></i>
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                            "Some quick example text to build on the card title
                            and make up the bulk of the card's content."
                          </p>
                          <div className="d-flex align-items-center pt-2">
                            <img
                              src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                              alt="bootstrap testimonial carousel slider 2"
                            />
                            <div>
                              <h5 className="card-title fw-bold">John Doe</h5>
                              <span className="text-secondary">
                                CEO, Example Company
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card-sm rounded-3">
                        <div className="quotes display-2 text-body-tertiary">
                          <i className="bi bi-quote"></i>
                        </div>
                        <div className="card-body">
                          <p className="card-text">
                            "Some quick example text to build on the card title
                            and make up the bulk of the card's content."
                          </p>
                          <div className="d-flex align-items-center pt-2">
                            <img
                              src="https://codingyaar.com/wp-content/uploads/bootstrap-profile-card-image.jpg"
                              alt="bootstrap testimonial carousel slider 2"
                            />
                            <div>
                              <h5 className="card-title fw-bold">John Doe</h5>
                              <span className="text-secondary">
                                CEO, Example Company
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#testimonialCarousel"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#testimonialCarousel"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="fw-bolder  p-4 noSpace  text-end">
            <button className="btn btn-info" onClick={handleFAQ}>
              Frequently Asked Questions
            </button>
          </div>

          <footer className="fw-bolder display-center p-4 noSpace ">
            <p>© {new Date().getFullYear()} Webstrong Solutions.</p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default LandingScreen;
