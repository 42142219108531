import React from "react";
import { Modal } from "react-bootstrap";
import "../assets/styles/style.css";

function LogoutModal({
  show,
  handleLogoutOnly,
  handleConfirm,
  messageLogout,
  messageLogoutType,
  handleClose,
  logoutLoading,
  OTP,
  setOTP,
  OTPVerify,
  OTPload,
  sendLogoutOTP,
  timerActive,
  formatTime,
  timeLeft,
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Logout</Modal.Title>
      </Modal.Header>
      {sendLogoutOTP !== "success" ? (
        <div>
          <Modal.Body>
            <div>
              You are trying to logout from this device.
              <br />
              Would you like to delete the existing entries?
              <div className="pt-3">
                Click '<span className="fw-bold">Yes</span>' to delete all
                entries or '<span className="fw-bold">No</span>' to keep
                existing entries.
              </div>
            </div>
          </Modal.Body>

          <div>
            <div className="d-flex justify-content-end align-items-center  p-2">
              <div>
                <button
                  className=" btn btn-warning me-3 "
                  onClick={handleLogoutOnly}
                >
                  No
                </button>
                {logoutLoading ? (
                  <div className=" btn ">
                    <div
                      className="ms-3 spinner-border text-success"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  <button className="  btn-danger btn" onClick={handleConfirm}>
                    Yes
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={OTPVerify}>
          <Modal.Body>
            <div className="pt-4  ">
              <h5>Please enter the OTP to verify the account.</h5>
              <div className="input-container pt-2">
                <input
                  type="text"
                  placeholder="Enter verification OTP"
                  value={OTP}
                  maxLength={6}
                  required
                  onChange={(e) =>
                    setOTP(e.target.value.replace(/\D/g, "").slice(0, 6))
                  }
                  className="form-control mt-4   input-with-icon"
                />
              </div>
            </div>
            <div>
              {timerActive && (
                <div className="timer text-danger ">
                  <p style={{ fontSize: "14px" }}>
                    OTP will expire in: <span className="fw-bold">{formatTime(timeLeft)}</span> minutes.
                  </p>
                </div>
              )}
            </div>
          </Modal.Body>
          <div className="text-center pb-4">
            <button className="save-btn   " disabled={OTPload} type="submit">
              {OTPload && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}

              {OTPload ? "Submiting..." : "Submit"}
            </button>
          </div>{" "}
        </form>
      )}
      <br />

      <span className="noSpace SuccessMessage-div">
        {messageLogout && (
          <div
            className={` ${
              messageLogoutType === "success" ? "alert-success" : "alert-danger"
            }`}
          >
            {messageLogoutType === "success" ? (
              <div className="successMessage">
                <span className="material-symbols-outlined   verticleAlign">
                  task_alt
                </span>{" "}
                <span className="verticleAlign"> {messageLogout} </span>
              </div>
            ) : (
              <div className="text-danger">
                <span className="material-symbols-outlined verticleAlign">
                  error_outline
                </span>{" "}
                <span className="verticleAlign">
                  {" "}
                  {messageLogout}{" "}
                </span>
              </div>
            )}
          </div>
        )}
      </span>
    </Modal>
  );
}

export default LogoutModal;
