import React, { useState, useEffect } from "react";
import { db } from "../db";
import "../assets/styles/style.css";
import PaginationEntriesPerPage from "./PaginationEntriesPerPage";
import ExpenseSorting from "./ExpenseSorting";
import ExpensePagination from "./ExpensePagination";
import { NumericFormat } from "react-number-format";

const ExpenseLists = ({
  filteredExpenses,
  handleEdit,
  handleDelete,
  activeRow,
  selectedCurrency,
  setActive,
}) => {
  const [userAddedCategories, setUserAddedCategories] = useState([]);
  const [sortBy, setSortBy] = useState("date");
  const [totalExpensesCount, setTotalExpensesCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    const fetchUserAddedCategories = async () => {
      try {
        const categoriesFromDB = await db.categories.toArray();
        setUserAddedCategories(categoriesFromDB);
      } catch (error) {
        console.error("Error fetching user-added categories:", error);
      }
    };
    fetchUserAddedCategories();
  }, []);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const expenses = [...filteredExpenses].sort((a, b) => {
    if (sortBy === "item") {
      return sortOrder === "asc"
        ? a.item.localeCompare(b.item)
        : b.item.localeCompare(a.item);
    } else if (sortBy === "category") {
      return sortOrder === "asc"
        ? a.category.localeCompare(b.category)
        : b.category.localeCompare(a.category);
    } else if (sortBy === "amount") {
      return sortOrder === "asc" ? a.amount - b.amount : b.amount - a.amount;
    } else if (sortBy === "quantity") {
      return sortOrder === "asc"
        ? a.quantity - b.quantity
        : b.quantity - a.quantity;
    } else if (sortBy === "date") {
      return sortOrder === "asc"
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    }
    return 0;
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    const storedValue = localStorage.getItem("itemsPerPage") || 10;
    return parseInt(storedValue);
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalFilteredExpenses = expenses?.filter(
    (result) => result.isDeleted !== 1
  );
  const totalItemsCount = totalFilteredExpenses.length;

  const currentItems = totalFilteredExpenses.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(totalItemsCount / itemsPerPage);

  const changeItemsPerPage = (event) => {
    const value = event.target.value;
    if (value === "All") {
      setCurrentPage(1);
      setItemsPerPage(totalExpensesCount);
    } else {
      const intValue = parseInt(value);
      setItemsPerPage(intValue);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    const storedCurrentPage = localStorage.getItem("currentPageExpense");
    const storedItemsPerPage = localStorage.getItem("itemsPerPageExpense");

    if (storedCurrentPage && storedItemsPerPage) {
      setCurrentPage(parseInt(storedCurrentPage));
      setItemsPerPage(parseInt(storedItemsPerPage));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("currentPageExpense", currentPage);
    localStorage.setItem("itemsPerPageExpense", itemsPerPage);
  }, [currentPage, itemsPerPage]);

  window.onbeforeunload = function () {
    localStorage.removeItem("currentPageExpense");
    localStorage.removeItem("itemsPerPageExpense");
  };
  useEffect(() => {
    db.expenses
      .count()
      .then((count) => {
        setTotalExpensesCount(count);
      })
      .catch((error) => {
        console.error("Error fetching total expenses count:", error);
      });
  }, []);

  return (
    <div className=" expense-list-div fixTableHead">
      <div>
        <table className="table text-size pt-4">
          <ExpenseSorting
            sortBy={sortBy}
            sortOrder={sortOrder}
            handleSort={handleSort}
          />
          <tbody>
            {currentItems.map((expense, index) => (
              <tr
                key={expense.id}
                className={activeRow === expense.id ? "ActiveRow " : ""}
                onClick={() => setActive(expense.id)}
              >
                <td className="hide-sm-screen ">
                  {index + 1 + (currentPage - 1) * itemsPerPage}
                </td>
                <td className="content-items ">{expense.date}</td>
                <td className="content-items">{expense.item}</td>
                <td className="noSpace hide-sm-screen content-items ">
                  {
                    userAddedCategories.find(
                      (category) => category.id === parseInt(expense.category)
                    )?.name
                  }
                </td>
                <td className="noSpace text-center hide-sm-screen content-items">
                  {expense.quantity}
                </td>
                <td className="content-items  ">
                  <div className="currencyIcons float-end ">
                    <NumericFormat
                      value={parseFloat(expense.amount).toFixed(2)}
                      displayType={"text"}
                      thousandsGroupStyle="lakh"
                      thousandSeparator=","
                      className="verticleAlign"
                      prefix={selectedCurrency.label}
                    />
                  </div>
                </td>
                <td className="noSpace display-center pt-0 ">
                  <button
                    className="expense-delete-edit-btn  btn edit-btn"
                    onClick={() => {
                      handleEdit(expense.id);
                    }}
                  >
                    <span className="material-symbols-outlined">edit</span>
                  </button>
                  <button
                    className=" expense-delete-edit-btn delete-btn btn"
                    onClick={() => handleDelete(expense.id)}
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="display-between ">
        <PaginationEntriesPerPage
          value={itemsPerPage}
          totalCount={totalExpensesCount}
          onChange={changeItemsPerPage}
        />
        <ExpensePagination
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={paginate}
        />
      </div>
    </div>
  );
};

export default ExpenseLists;
